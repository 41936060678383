// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.related-post-item {
  width: 90% !important;
}
.related-p-c {
  margin: 5rem auto 3rem auto;
}
.related-p-c > p {
  font-size: 40px;
  margin-bottom: 40px;
  color: var(--primary);
}
`, "",{"version":3,"sources":["webpack://./src/pages/post/sections/related/related.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;AACA;EACE,2BAA2B;AAC7B;AACA;EACE,eAAe;EACf,mBAAmB;EACnB,qBAAqB;AACvB","sourcesContent":[".related-post-item {\n  width: 90% !important;\n}\n.related-p-c {\n  margin: 5rem auto 3rem auto;\n}\n.related-p-c > p {\n  font-size: 40px;\n  margin-bottom: 40px;\n  color: var(--primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
