// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.promos-c {
  height: 100vh;
  width: 100%;
  min-height: 700px;
  overflow: hidden;
  position: relative;
}
.promos-header-img {
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 85px;
  left: 0;
  z-index: -1;
}
.promos-c > h1 {
  position: absolute;
  left: 6%;
  top: 140px;
  font-size: 4rem;
  font-family: "Butler";
  font-weight: 900;
  text-align: left;
  color: var(--primary);
}
.promos-h-btn {
  position: absolute;
  left: 6%;
  top: 440px;
}

@media (min-width: 1340px) {
  .promos-c > h1 {
    top: 230px;
  }
  .promos-h-btn {
    top: 550px;
  }
}

@media (max-width: 1210px) {
  .promos-c > h1 {
    font-size: 3.3rem;
  }
}

@media (max-width: 1050px) {
  .promos-header-img {
    height: 90%;
  }
  .promos-c > h1 {
    font-size: 2.7rem;
  }
  .promos-h-btn {
    top: 330px;
  }
}

@media (max-width: 666px) {
  .promos-header-img {
    top: 200px;
  }
  .promos-h-btn {
    width: 150px;
    left: 20px;
  }
}
@media (max-width: 470px) {
  .promos-c > h1 {
    font-size: 2.3em;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/promotions/sections/hero/hero.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,iBAAiB;EACjB,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,WAAW;AACb;AACA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,eAAe;EACf,qBAAqB;EACrB,gBAAgB;EAChB,gBAAgB;EAChB,qBAAqB;AACvB;AACA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;AACZ;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,WAAW;EACb;EACA;IACE,iBAAiB;EACnB;EACA;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,YAAY;IACZ,UAAU;EACZ;AACF;AACA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".promos-c {\n  height: 100vh;\n  width: 100%;\n  min-height: 700px;\n  overflow: hidden;\n  position: relative;\n}\n.promos-header-img {\n  width: 100%;\n  object-fit: cover;\n  position: absolute;\n  top: 85px;\n  left: 0;\n  z-index: -1;\n}\n.promos-c > h1 {\n  position: absolute;\n  left: 6%;\n  top: 140px;\n  font-size: 4rem;\n  font-family: \"Butler\";\n  font-weight: 900;\n  text-align: left;\n  color: var(--primary);\n}\n.promos-h-btn {\n  position: absolute;\n  left: 6%;\n  top: 440px;\n}\n\n@media (min-width: 1340px) {\n  .promos-c > h1 {\n    top: 230px;\n  }\n  .promos-h-btn {\n    top: 550px;\n  }\n}\n\n@media (max-width: 1210px) {\n  .promos-c > h1 {\n    font-size: 3.3rem;\n  }\n}\n\n@media (max-width: 1050px) {\n  .promos-header-img {\n    height: 90%;\n  }\n  .promos-c > h1 {\n    font-size: 2.7rem;\n  }\n  .promos-h-btn {\n    top: 330px;\n  }\n}\n\n@media (max-width: 666px) {\n  .promos-header-img {\n    top: 200px;\n  }\n  .promos-h-btn {\n    width: 150px;\n    left: 20px;\n  }\n}\n@media (max-width: 470px) {\n  .promos-c > h1 {\n    font-size: 2.3em;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
