import React, { useEffect, useState } from "react";
import "./body.css";
import { API, SERVER_DOMAIN } from "../../../../assets/staticData";
import { useNavigate } from "react-router";
import { createSearchParams } from "react-router-dom";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import VideoPlayer from "../../../../components/video-player/VideoPlayer";

export default function Body({ user }) {
  const navigate = useNavigate();

  const [articles, setArticles] = useState([]);
  const [videos, setVideos] = useState([]);
  const [videoPlayer, setVideoPlayer] = useState(null);

  const [open, setOpen] = useState(false);
  const info = [
    {
      label: "Articles",
      value: 32,
      icon: require("../../../../assets/icons/author-1.png"),
    },
    {
      label: "Words",
      value: 4392,
      icon: require("../../../../assets/icons/author-2.png"),
    },
    {
      label: "Reactions",
      value: 734,
      icon: require("../../../../assets/icons/author-3.png"),
    },
    {
      label: "Months",
      value: 8,
      icon: require("../../../../assets/icons/author-4.png"),
    },
  ];

  const apiKey = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `${API}/articles?filters[authorId][$eq]=${user.id}&filters[public][$eq]=true&filters[lang][$eq]=English&populate=*`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${apiKey}`,
            },
            method: "GET",
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const res = await response.json();
        setArticles(res.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [user]);

    useEffect(() => {
      async function fetchData() {
        try {
          const response = await fetch(
            `${API}/videos?filters[authorId][$eq]=${user.id}&filters[public][$eq]=true&filters[lang][$eq]=English&populate=*`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${apiKey}`,
              },
              method: "GET",
            }
          );
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          const res = await response.json();
          setVideos(res.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }

      fetchData();
    }, [user]);

  const slides = user?.certificates?.filter(Boolean).map((certificate) => ({
    src: SERVER_DOMAIN + certificate,
  }));

  return (
    <>
      {user && (
        <>
          <div className="author-b-c">
            <div>
              <div className="author-b-title">
                <div></div>
                <p>{"Activities of " + user.username}</p>
              </div>

              <div className="info-box-c">
                {info.map((item, index) => (
                  <div key={index} className="info-box">
                    <img src={item.icon} />
                    <div>
                      <p>{item.value}</p>
                      <p>{item.label}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <div className="author-b-title">
                <div></div>
                <p>{"Certificates of " + user.username}</p>
              </div>

              <div className="info-box-c">
                {user?.certificates?.slice(0, 3).map((item, index) => (
                  <img
                    onClick={() => setOpen(true)}
                    key={index}
                    src={SERVER_DOMAIN + item}
                    style={{ width: "30%", cursor: "pointer" }}
                  />
                ))}
              </div>
            </div>
            <div>
              <div className="author-b-title">
                <div></div>
                <p>{"Latest Articles by " + user.username}</p>
              </div>

              <div
                className="info-box-c"
                style={{ gap: 10, flexWrap: "wrap", rowGap: 25 }}
              >
                {articles?.map((item, index) => (
                  <div
                    className="h-a-i"
                    style={{ width: "29.5%", minWidth: "auto" }}
                    key={index}
                    onClick={() => {
                      const postTitle = item?.attributes?.title
                        ?.replace(/\s+/g, "-")
                        .replace("?", "");
                      navigate(
                        {
                          pathname: `/post/${postTitle}`,
                          search: createSearchParams({
                            id: item.id,
                          }).toString(),
                        },
                        { state: item }
                      );
                    }}
                  >
                    <div className="h-a-i-img">
                      <img
                        src={
                          SERVER_DOMAIN +
                          item?.attributes?.image?.data?.attributes?.url
                        }
                      />
                    </div>

                    <div className="h-a-i-content">
                      <p
                        style={{
                          color: "gray",
                          fontSize: 13,
                          fontWeight: "500",
                          marginBottom: 3,
                        }}
                        className="blog-card-date"
                      >
                        {new Date(item.attributes.createdAt).toLocaleDateString(
                          "TR"
                        )}
                      </p>
                      <h3 className="blog-card-title">
                        {item.attributes.title}
                      </h3>
                      <p className="blog-card-des">
                        {item.attributes.subTitle.length > 100
                          ? item.attributes.subTitle.slice(0, 90) + "..."
                          : item.attributes.subTitle}
                      </p>
                    </div>

                    <div className="h-a-i-read flex">
                      Read
                      <ArrowCircleRightOutlinedIcon sx={{ fontSize: 20 }} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <div className="author-b-title">
                <div></div>
                <p>{"Latest Videos by " + user.username}</p>
              </div>

              <div className="info-box-c" style={{ gap: 20, flexWrap: "wrap" }}>
                {videos.length > 0 &&
                  videos.map((item, index) => (
                    <div
                      key={index}
                      className="l-v-i"
                      style={{ width: "30%" }}
                      onClick={() => setVideoPlayer(item.attributes.link)}
                    >
                      <div className="latest-vid-i flex">
                        <img
                          src={
                            SERVER_DOMAIN +
                            item?.attributes?.image?.data?.attributes?.url
                          }
                          className="vid-thumbnail"
                        />
                        <img
                          style={{ width: "25%", position: "relative" }}
                          src={require("../../../../assets/icons/play-icon.png")}
                        />
                      </div>

                      <p style={{ marginTop: 10 }}>{item.attributes.title}</p>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <Lightbox
            open={open}
            styles={{ root: { "--yarl__color_backdrop": "rgba(0, 0, 0, .8)" } }}
            close={() => setOpen(false)}
            slides={slides}
          />
          {videoPlayer && (
            <VideoPlayer setVideoPlayer={setVideoPlayer} link={videoPlayer} />
          )}
        </>
      )}
    </>
  );
}
