import React from "react";
import "./counters.css";
import CountUp from "react-countup";

export default function Counters() {
  return (
    <div className="counters-c">
      <div className="counter">
        <CountUp
          prefix="+"
          className="counter-n"
          end={100000}
          enableScrollSpy
          duration={2.5}
        />
        <h3>Traders</h3>
      </div>
      <div className="counter">
        <CountUp
          prefix="+"
          className="counter-n"
          end={200}
          enableScrollSpy
          duration={2.5}
        />
        <h3>Markets</h3>
      </div>
      <div className="counter">
        <CountUp prefix="+" className="counter-n" end={9} enableScrollSpy />
        <h3>Years of Trust</h3>
      </div>
      <div className="counter" style={{ borderRight: "none" }}>
        <CountUp
          prefix="+"
          className="counter-n"
          end={25}
          enableScrollSpy
          duration={2.5}
        />
        <h3>Countries</h3>
      </div>
    </div>
  );
}
