import React, { useEffect, useState } from "react";
import arrowUp from "../../assets/icons/arrow-down-icon.svg";

export default function ScrollToTopBtn() {
  const [scrolled, setScolled] = useState(false);

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 500) {
        setScolled(true);
      } else {
        setScolled(false);
      }
    });
  }, []);

  return (
    <div
      onClick={scrollUp}
      style={{
        transition: "0.4s",
        opacity: scrolled ? 1 : 0,
        visibility: scrolled ? "visible" : "hidden",
        position: "fixed",
        bottom: 20,
        left: 20,
        zIndex: 999999,
        width: 60,
        aspectRatio: 1,
        cursor: "pointer",
        borderRadius: 50,
        background: "var(--primary)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: `linear-gradient(
    to bottom,
    var(--lightBlue),
    var(--primary)
  )`,
      }}
    >
      <img src={arrowUp} style={{ transform: "rotate(180deg)", width: 30 }} />
    </div>
  );
}
