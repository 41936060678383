// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ab-history-c {
  min-height: 70vh;
  max-width: 1000px;
  margin: 0 auto;
}
.ab-hi-side-a,
.ab-hi-side-b {
  width: 50%;
  position: relative;
}
.ab-hi-side-a > img {
  width: 100%;
}
.ab-hi-side-b > h2 {
  color: var(--primary);
  text-transform: uppercase;
  font-size: 6rem;
  font-weight: 700;
  margin-left: -215px;
}
.ab-hi-side-b > h2::first-letter {
  color: #fff;
}
.ab-hi-side-b > p {
  text-align: justify;
  padding: 0 33px;
  font-weight: 500;
  margin: 20px 0;
  line-height: 25px;
}
.ab-badge {
  position: absolute;
  left: -50px;
  bottom: -40px;
  width: 150px !important;
}

@media (max-width: 1100px) {
  .ab-hi-side-b > h2::first-letter {
    color: var(--primary);
  }
  .ab-hi-side-b > h2 {
    font-size: 3rem;
    margin-left: 0;
  }
  .ab-hi-side-a > img {
    width: 90%;
  }
  .ab-badge {
    bottom: 0;
    left: 0;
  }
}

@media (max-width: 650px) {
  .ab-history-c {
    flex-direction: column;
    margin-top: 30px;
  }
  .ab-hi-side-a,
  .ab-hi-side-b {
    width: 100%;
  }
  .ab-hi-side-b > h2 {
    font-size: 2.5rem;
    margin-top: 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/about/sections/history/history.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;AAChB;AACA;;EAEE,UAAU;EACV,kBAAkB;AACpB;AACA;EACE,WAAW;AACb;AACA;EACE,qBAAqB;EACrB,yBAAyB;EACzB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,WAAW;AACb;AACA;EACE,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,iBAAiB;AACnB;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE;IACE,qBAAqB;EACvB;EACA;IACE,eAAe;IACf,cAAc;EAChB;EACA;IACE,UAAU;EACZ;EACA;IACE,SAAS;IACT,OAAO;EACT;AACF;;AAEA;EACE;IACE,sBAAsB;IACtB,gBAAgB;EAClB;EACA;;IAEE,WAAW;EACb;EACA;IACE,iBAAiB;IACjB,gBAAgB;EAClB;AACF","sourcesContent":[".ab-history-c {\n  min-height: 70vh;\n  max-width: 1000px;\n  margin: 0 auto;\n}\n.ab-hi-side-a,\n.ab-hi-side-b {\n  width: 50%;\n  position: relative;\n}\n.ab-hi-side-a > img {\n  width: 100%;\n}\n.ab-hi-side-b > h2 {\n  color: var(--primary);\n  text-transform: uppercase;\n  font-size: 6rem;\n  font-weight: 700;\n  margin-left: -215px;\n}\n.ab-hi-side-b > h2::first-letter {\n  color: #fff;\n}\n.ab-hi-side-b > p {\n  text-align: justify;\n  padding: 0 33px;\n  font-weight: 500;\n  margin: 20px 0;\n  line-height: 25px;\n}\n.ab-badge {\n  position: absolute;\n  left: -50px;\n  bottom: -40px;\n  width: 150px !important;\n}\n\n@media (max-width: 1100px) {\n  .ab-hi-side-b > h2::first-letter {\n    color: var(--primary);\n  }\n  .ab-hi-side-b > h2 {\n    font-size: 3rem;\n    margin-left: 0;\n  }\n  .ab-hi-side-a > img {\n    width: 90%;\n  }\n  .ab-badge {\n    bottom: 0;\n    left: 0;\n  }\n}\n\n@media (max-width: 650px) {\n  .ab-history-c {\n    flex-direction: column;\n    margin-top: 30px;\n  }\n  .ab-hi-side-a,\n  .ab-hi-side-b {\n    width: 100%;\n  }\n  .ab-hi-side-b > h2 {\n    font-size: 2.5rem;\n    margin-top: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
