// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ab-hero-c {
  min-height: 95vh;
  position: relative;
}
.ab-hero-c > h1 {
  font-family: "Butler";
  font-size: 8rem;
  text-transform: uppercase;
  color: var(--primary);
  font-weight: 900;
  z-index: 2;
  position: absolute;
  top: 15%;
}

.ab-hero-c > img {
  margin-top: 21vh;
  height: 70vh;
}

@media (max-width: 850px) {
  .ab-hero-c > h1 {
    font-size: 5rem;
  }
  .ab-hero-c > img {
    margin-top: 14vh;
  }
}
@media (max-width: 550px) {
  .ab-hero-c > h1 {
    font-size: 3.5rem;
  }
  .ab-hero-c > img {
    margin-top: 10.5vh;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/about/sections/hero/hero.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,qBAAqB;EACrB,eAAe;EACf,yBAAyB;EACzB,qBAAqB;EACrB,gBAAgB;EAChB,UAAU;EACV,kBAAkB;EAClB,QAAQ;AACV;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE;IACE,eAAe;EACjB;EACA;IACE,gBAAgB;EAClB;AACF;AACA;EACE;IACE,iBAAiB;EACnB;EACA;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".ab-hero-c {\n  min-height: 95vh;\n  position: relative;\n}\n.ab-hero-c > h1 {\n  font-family: \"Butler\";\n  font-size: 8rem;\n  text-transform: uppercase;\n  color: var(--primary);\n  font-weight: 900;\n  z-index: 2;\n  position: absolute;\n  top: 15%;\n}\n\n.ab-hero-c > img {\n  margin-top: 21vh;\n  height: 70vh;\n}\n\n@media (max-width: 850px) {\n  .ab-hero-c > h1 {\n    font-size: 5rem;\n  }\n  .ab-hero-c > img {\n    margin-top: 14vh;\n  }\n}\n@media (max-width: 550px) {\n  .ab-hero-c > h1 {\n    font-size: 3.5rem;\n  }\n  .ab-hero-c > img {\n    margin-top: 10.5vh;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
