import React, { useEffect, useState } from "react";
import "./countdown.css";

const CountdownTimer = ({ targetDate }) => {
  const [timeLeft, setTimeLeft] = useState({
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const distance = targetDate - now;

      if (distance < 0) {
        clearInterval(interval);
        setTimeLeft({
          days: "00",
          hours: "00",
          minutes: "00",
          seconds: "00",
        });
      } else {
        setTimeLeft({
          days: formatNumber(Math.floor(distance / (1000 * 60 * 60 * 24))),
          hours: formatNumber(
            Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
          ),
          minutes: formatNumber(
            Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
          ),
          seconds: formatNumber(Math.floor((distance % (1000 * 60)) / 1000)),
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  const formatNumber = (number) => {
    return number.toString().padStart(2, "0");
  };

  const renderTimeUnit = (value, label) => {
    const digits = value.split("");

    return (
      <div className="countdown-unit">
        <span className="countdown-label">{label.toUpperCase()}</span>
        <div className="countdown-digits">
          {digits.map((digit, index) => (
            <span key={index} className="countdown-digit">
              {digit}
            </span>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="countdown">
      {renderTimeUnit(timeLeft.days, "days")}
      <span className="countdown-separator">:</span>
      {renderTimeUnit(timeLeft.hours, "hours")}
      <span className="countdown-separator">:</span>
      {renderTimeUnit(timeLeft.minutes, "minutes")}
      <span className="countdown-separator">:</span>
      {renderTimeUnit(timeLeft.seconds, "seconds")}
    </div>
  );
};

export default CountdownTimer;
