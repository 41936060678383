import React, { useEffect, useState } from "react";
import Hero from "./sections/hero/Hero";
import { API } from "../../assets/staticData";
import { useSearchParams } from "react-router-dom";
import Body from "./sections/body/Body";

const apiKey = process.env.REACT_APP_API_KEY;

export default function Author() {
  const [data, setData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${API}/users/${searchParams.get("id")}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          method: "GET",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const res = await response.json();
        setData(res);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <div>
      <Hero user={data} />
      <Body user={data} />
    </div>
  );
}
