import React, { useEffect, useRef, useState } from "react";
import "./hero.css";
import { SERVER_DOMAIN } from "../../../../assets/staticData";
import { Link } from "react-router-dom";

export default function Hero({ data }) {
  const otherElementRef = useRef(null);
  const overlayRef = useRef(null);
  const [overlayHeight, setOverlayHeight] = useState(0);

  useEffect(() => {
    const adjustOverlayHeight = () => {
      if (otherElementRef.current) {
        const otherElementHeight = otherElementRef.current.offsetHeight;
        setOverlayHeight(otherElementHeight);
      }
    };

    adjustOverlayHeight(); 

    window.addEventListener("resize", adjustOverlayHeight);

    return () => {
      window.removeEventListener("resize", adjustOverlayHeight);
    };
  }, [data]);

  return (
    <div className="aw-h-c">
      <h1>Deposit & Withdrawal</h1>

      <div ref={otherElementRef} className="dw-table flex-col">
        <div className="dw-header-c">
          {data.data &&
            data.data[0]?.header.map((item, index) => (
              <div className="dw-header-i flex-col" key={index}>
                <img src={SERVER_DOMAIN + item.icon} />
                <p>{item.title}</p>

                <Link target="_" to={item.btnL}>
                  {item.btnT}
                </Link>

                <div
                  ref={overlayRef}
                  className="dw-header-overlay"
                  style={{ height: `${overlayHeight - 280}px` }}></div>
              </div>
            ))}
        </div>

        <div className="dw-sections-c">
          {data.data &&
            data.data[0]?.sections.map((item, index) => (
              <div key={index} className="dw-section">
                <div className="dw-section-side-a">
                  <h3>{item.title}</h3>
                  <p>{item.desc}</p>
                </div>

                <div className="dw-section-side-b flex-col">
                  {item.rows.map((i, ind) => (
                    <div className="dw-row" key={ind}>
                      <div>
                        <p>{i.n}</p>
                        <div className="border-down-name"></div>
                      </div>
                      <div>
                        <p>{i.v1}</p>
                        <div className="border-down"></div>
                      </div>
                      <div>
                        <p>{i.v2}</p>
                        <div className="border-down"></div>
                      </div>
                      <div>
                        <p>{i.v3}</p>
                        <div className="border-down"></div>
                      </div>
                      <div>
                        <p>{i.v4}</p>
                        <div className="border-down"></div>
                      </div>
                      <div>
                        <p>{i.v5}</p>
                        <div className="border-down"></div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
