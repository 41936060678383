import React, { useEffect, useState } from "react";
import "./faq.css";
import { API } from "../../../../assets/staticData";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { animated, useSpring } from "@react-spring/web";
import { Link } from "react-router-dom";
import bull from "../../../../assets/images/bull.svg";
import PaymentMethods from "../payment-methods/PaymentMethods";

export default function Faq() {
  const [data, setData] = useState([]);
  const [opens, setOpens] = useState([]);

  const apiKey = process.env.REACT_APP_API_KEY;

  const [rotates, api] = useSpring(() => ({
    from: { x: 0 },
  }));

  const animate = () => {
    api.start({
      from: {
        x: 0,
      },
      to: {
        x: 1,
      },
    });
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${API}/contact-us`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          method: "GET",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch languages");
        }
        const data = await response.json();
        setData(data.data?.attributes?.contactData[0]?.faqs?.slice(0, 4));
        let opensDocs = [];
        data.data?.attributes?.contactData[0]?.faqs?.map((item, index) => {
          opensDocs.push(index == 0 ? { open: true } : { open: false });
        });
        setOpens(opensDocs);
      } catch (error) {
        console.error("Error fetching languages:", error);
      }
    }

    fetchData();
  }, []);
  return (
    <div className="bg-gradient">
      <div className="faqs-c">
        <img src={bull} className="bull-img" />
        {/* <h2>Frequently Asked Questions</h2>
        <div className="faqs-c-c">
          <div className="faq-search-c">
            <MagnifyingGlassIcon
              width={20}
              style={{ color: "var(--primary)" }}
            />
            <p>Search FAQ</p>
          </div>
          <div>
            {data?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="faq-c"
                  style={{
                    border: "solid",
                    borderWidth: opens[index]?.open ? 2 : 0,
                    borderColor: "var(--primary)",
                    minHeight: opens[index]?.open ? 120 : null,
                  }}
                  onClick={() => {
                    if (opens[index]?.open == false) {
                      setOpens([
                        ...opens.map((item, idx) => {
                          if (idx === index) {
                            return { ...item, open: true };
                          } else {
                            return item;
                          }
                        }),
                      ]);
                    } else {
                      setOpens([
                        ...opens.map((item, idx) => {
                          if (idx === index) {
                            return { ...item, open: false };
                          } else {
                            return item;
                          }
                        }),
                      ]);
                    }
                  }}
                >
                  <div className="faq-q-c flex">
                    <p>{item.q}</p>
                  </div>
                  <div
                    style={{
                      position: opens[index]?.open ? "static" : "absolute",
                      visibility: opens[index]?.open ? "visible" : "hidden",
                    }}
                    className="faq-a-c"
                  >
                    <p>{item.a}</p>
                  </div>
                  <animated.div
                    className="flex"
                    style={{
                      position: "absolute",
                      right: 25,
                      top: 25,
                      border: "solid",
                      borderWidth: 1.5,
                      borderRadius: 50,
                      borderColor: "var(--primary)",
                      width: 45,
                      height: 45,
                      backgroundColor: opens[index]?.open
                        ? "var(--primary)"
                        : null,
                      transform: opens[index]?.open
                        ? "rotate(0)"
                        : "rotate(-90deg)",
                      transition: "0.3s",
                    }}
                  >
                    <ChevronDownIcon
                      width={28}
                      style={{
                        color: opens[index]?.open ? "#fff" : "var(--primary)",
                      }}
                    />
                  </animated.div>
                </div>
              );
            })}
          </div>
          <div className="faq-contact-us-info">
            <p>
              Have a Question?{" "}
              <Link
                to="Contact"
                style={{ color: "var(--primary)", fontWeight: 700 }}
              >
                Click Here
              </Link>
            </p>
            <p>
              Contact Us{" "}
              <a
                style={{ textDecoration: "none", color: "var(--primary)" }}
                href="tel:+99643434325665"
              >
                +996 (4343) 4325665
              </a>
            </p>
          </div>
        </div> */}
      </div>
      <PaymentMethods />
    </div>
  );
}
