import React, { useEffect, useState } from "react";
import "./about.css";
import aboutLogo from "../../../../assets/images/about-logo.svg";
import flame from "../../../../assets/icons/flame-icon.svg";
import aRight from "../../../../assets/images/about-right.svg";
import aMiddle from "../../../../assets/images/about-middle.svg";
import aLeft from "../../../../assets/images/about-left.svg";
import { API } from "../../../../assets/staticData";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

export default function About() {
  const [data, setData] = useState([]);
  const apiKey = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${API}/about-us`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          method: "GET",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setData(data.data?.attributes?.homeAboutData[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="about-c">
      <h2>About Mishov Markets</h2>
      <div className="about-content-c">
        <div className="about-side-a">
          <img src={aLeft} className="a-img-side" />
          <img src={aMiddle} className="a-img-side" />
          <img src={aRight} className="a-img-side a-right-img" />
        </div>
        <div className="about-side-b">
          {parse(`${data?.introduction}`)}

          <Link target="_" to={data.btnL} className="hero-btn">
            <img src={flame} />
            <p>{data.btnT}</p>
          </Link>
        </div>
      </div>
      <img className="about-logo" src={aboutLogo} />
    </div>
  );
}
