// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-c {
  width: 90%;
  height: 90vh;
  margin: 0 auto;
  margin-top: 30px;
}
.map-img-c {
  position: relative;
}
.map-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.map-pulsing-circle {
  position: absolute;
  cursor: pointer;
  width: 2.2%;

}
.map-card-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.map-card-content > div > h4 {
  color: var(--primary);
  font-weight: 700;
}
.map-card-content > div > p {
  font-size: 12px;
  font-weight: 500;
}

.map-mini-dot {
  position: absolute;
  width: 8px;
  border-radius: 10px;
  background-color: var(--link);
  aspect-ratio: 1;
}

@media (max-width: 1100px) {
  .map-mini-dot {
    width: 6px;
  }
}

@media (max-width: 600px) {
  .map-c {
    height: 45vh;
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/home/sections/map/map.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;EACZ,cAAc;EACd,gBAAgB;AAClB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,WAAW;;AAEb;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,YAAY;AACd;AACA;EACE,qBAAqB;EACrB,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,mBAAmB;EACnB,6BAA6B;EAC7B,eAAe;AACjB;;AAEA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,YAAY;IACZ,WAAW;EACb;AACF","sourcesContent":[".map-c {\n  width: 90%;\n  height: 90vh;\n  margin: 0 auto;\n  margin-top: 30px;\n}\n.map-img-c {\n  position: relative;\n}\n.map-img {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n.map-pulsing-circle {\n  position: absolute;\n  cursor: pointer;\n  width: 2.2%;\n\n}\n.map-card-content {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  height: 100%;\n}\n.map-card-content > div > h4 {\n  color: var(--primary);\n  font-weight: 700;\n}\n.map-card-content > div > p {\n  font-size: 12px;\n  font-weight: 500;\n}\n\n.map-mini-dot {\n  position: absolute;\n  width: 8px;\n  border-radius: 10px;\n  background-color: var(--link);\n  aspect-ratio: 1;\n}\n\n@media (max-width: 1100px) {\n  .map-mini-dot {\n    width: 6px;\n  }\n}\n\n@media (max-width: 600px) {\n  .map-c {\n    height: 45vh;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
