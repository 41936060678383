// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-player-c {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #00000040;
  z-index: 9;
}
.video-player-m-c {
  width: 55%;
  height: 60%;
  background-color: #fff;
  border-radius: 10px;
  max-width: 900px;
}
`, "",{"version":3,"sources":["webpack://./src/components/video-player/video-player.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,eAAe;EACf,MAAM;EACN,OAAO;EACP,2BAA2B;EAC3B,UAAU;AACZ;AACA;EACE,UAAU;EACV,WAAW;EACX,sBAAsB;EACtB,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".video-player-c {\n  width: 100vw;\n  height: 100vh;\n  position: fixed;\n  top: 0;\n  left: 0;\n  background-color: #00000040;\n  z-index: 9;\n}\n.video-player-m-c {\n  width: 55%;\n  height: 60%;\n  background-color: #fff;\n  border-radius: 10px;\n  max-width: 900px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
