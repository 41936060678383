// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l-v-c {
  margin: 5rem auto;
}

.l-v-c > h2 {
  color: var(--primary);
  font-size: 3.5rem;
}
.l-v-m-c-o {
  background-color: #e6e8f0;
  width: 100vw;
  margin-top: 30px;
  padding: 30px 0;
}
.l-v-m-c {
  margin: 0 auto;
  min-height: 250px;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  max-width: 1250px;
  flex-wrap: wrap;
  gap: 20px;
}
.l-v-i {
  width: 22%;
  min-width: 265px;
  padding-top: 20px;
}

.l-v-i > p {
  font-weight: 500;
  text-align: justify;
  padding: 0 5px;
}
.latest-vid-i {
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  min-height: 170px;
}
.latest-vid-i:hover .vid-thumbnail {
  transform: scale(1.07);
}
@media (max-width: 600px) {
  .l-v-i {
    width: 80% !important;
  }
  .l-v-c > h2 {
    font-size: 2.6rem;
  }
  .latest-vid-i {
    min-height: 190px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/blog/sections/latest-vids/latest-vids.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;EACrB,iBAAiB;AACnB;AACA;EACE,yBAAyB;EACzB,YAAY;EACZ,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,cAAc;EACd,iBAAiB;EACjB,aAAa;EACb,uBAAuB;EACvB,6BAA6B;EAC7B,iBAAiB;EACjB,eAAe;EACf,SAAS;AACX;AACA;EACE,UAAU;EACV,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,cAAc;AAChB;AACA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,iBAAiB;AACnB;AACA;EACE,sBAAsB;AACxB;AACA;EACE;IACE,qBAAqB;EACvB;EACA;IACE,iBAAiB;EACnB;EACA;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".l-v-c {\n  margin: 5rem auto;\n}\n\n.l-v-c > h2 {\n  color: var(--primary);\n  font-size: 3.5rem;\n}\n.l-v-m-c-o {\n  background-color: #e6e8f0;\n  width: 100vw;\n  margin-top: 30px;\n  padding: 30px 0;\n}\n.l-v-m-c {\n  margin: 0 auto;\n  min-height: 250px;\n  display: flex;\n  align-items: flex-start;\n  justify-content: space-around;\n  max-width: 1250px;\n  flex-wrap: wrap;\n  gap: 20px;\n}\n.l-v-i {\n  width: 22%;\n  min-width: 265px;\n  padding-top: 20px;\n}\n\n.l-v-i > p {\n  font-weight: 500;\n  text-align: justify;\n  padding: 0 5px;\n}\n.latest-vid-i {\n  border-radius: 10px;\n  overflow: hidden;\n  cursor: pointer;\n  position: relative;\n  min-height: 170px;\n}\n.latest-vid-i:hover .vid-thumbnail {\n  transform: scale(1.07);\n}\n@media (max-width: 600px) {\n  .l-v-i {\n    width: 80% !important;\n  }\n  .l-v-c > h2 {\n    font-size: 2.6rem;\n  }\n  .latest-vid-i {\n    min-height: 190px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
