import React from "react";
import { SERVER_DOMAIN } from "../../../../assets/staticData";

export default function Hero({ data }) {
  return (
    <div className="ab-hero-c flex-col">
      <h1>Blog</h1>
      <img src={data.images && SERVER_DOMAIN + data.images[0].headerImg} />
    </div>
  );
}
