// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-i-c {
  margin: 3rem auto;
}
.p-i-i {
  gap: 15px;
  margin-bottom: 20px;
  cursor: pointer;
}
.p-i-i > p {
  font-weight: 500;
  font-size: 20px;
}
.p-i-blue-ball {
  background-color: #236af2;
  width: 45px;
  aspect-ratio: 1;
  border-radius: 50px;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
}
.p-i-blue-line {
  height: 50px;
  width: 4px;
  background-color: #236af2;
}
`, "",{"version":3,"sources":["webpack://./src/pages/analysis/sections/instructor/instructor.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;AACA;EACE,SAAS;EACT,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,yBAAyB;EACzB,WAAW;EACX,eAAe;EACf,mBAAmB;EACnB,WAAW;EACX,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,YAAY;EACZ,UAAU;EACV,yBAAyB;AAC3B","sourcesContent":[".p-i-c {\n  margin: 3rem auto;\n}\n.p-i-i {\n  gap: 15px;\n  margin-bottom: 20px;\n  cursor: pointer;\n}\n.p-i-i > p {\n  font-weight: 500;\n  font-size: 20px;\n}\n.p-i-blue-ball {\n  background-color: #236af2;\n  width: 45px;\n  aspect-ratio: 1;\n  border-radius: 50px;\n  color: #fff;\n  font-weight: 600;\n  font-size: 18px;\n}\n.p-i-blue-line {\n  height: 50px;\n  width: 4px;\n  background-color: #236af2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
