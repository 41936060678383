import React, { useEffect, useState } from "react";
import "./summary.css";
import XIcon from "@mui/icons-material/X";
import TelegramIcon from "@mui/icons-material/Telegram";
import facebookIcon from "../../../../assets/icons/facebook-icon.svg";
import linkedInIcon from "../../../../assets/icons/linkedin-icon.svg";
import { API, SERVER_DOMAIN } from "../../../../assets/staticData";
import { useNavigate } from "react-router";
import { createSearchParams } from "react-router-dom";

export default function Summary({ item, endPoint, shareSec }) {
  const [iconHovered, setIconHovered] = useState("");
  const [count, setCount] = useState(item.attributes.likes || 0);

  const apiKey = process.env.REACT_APP_API_KEY;

  const navigate = useNavigate();

  useEffect(() => {
    const liked = localStorage.getItem(`voted-post-${item?.id}`);
    if (liked) {
      setIconHovered(liked);
    }
  }, [item?.id]);

  const handleIconClick = async (iconType) => {
    const previousReaction = localStorage.getItem(`voted-post-${item?.id}`);

    if (iconHovered === iconType) {
      setIconHovered("");
      localStorage.removeItem(`voted-post-${item?.id}`);
      setCount(count - 1);

      try {
        const response = await fetch(`${API + endPoint + "/" + item.id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          method: "PUT",
          body: JSON.stringify({
            data: {
              likes: count - 1,
            },
          }),
        });
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      setIconHovered(iconType);
      localStorage.setItem(`voted-post-${item?.id}`, iconType);
      if (!previousReaction) {
        setCount(count + 1);

        try {
          const response = await fetch(`${API + endPoint + "/" + item.id}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${apiKey}`,
            },
            method: "PUT",
            body: JSON.stringify({
              data: {
                likes: count + 1,
              },
            }),
          });
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    }
  };

  return (
    <>
      <div className="post-summary-c">
        <h2>Summary</h2>
        <p ref={shareSec}>{item.attributes.summary}</p>

        <div className="post-summary-footer">
          <div className="p-s-f-side-a">
            <img
              onClick={() =>
                navigate({
                  pathname: `/authors/${item.attributes?.author?.replace(
                    /\s+/g,
                    "-"
                  )}`,
                  search: createSearchParams({
                    id: item.attributes?.authorId,
                  }).toString(),
                })
              }
              style={{ cursor: "pointer" }}
              src={SERVER_DOMAIN + item.attributes.authorPFP}
            />
            <p>
              {item.attributes.authorOpinion?.length > 150
                ? item.attributes?.authorOpinion?.slice(0, 145) + "..."
                : item.attributes.authorOpinion}
            </p>
          </div>

          <div className="p-s-f-side-b">
            <p>Was this helpful?</p>

            <div onClick={() => handleIconClick("like")}>
              <img
                src={
                  iconHovered == "like"
                    ? require("../../../../assets/icons/like-filled.png")
                    : require("../../../../assets/icons/like.png")
                }
              />
            </div>
            <div onClick={() => handleIconClick("heart")}>
              <img
                src={
                  iconHovered == "heart"
                    ? require("../../../../assets/icons/heart-filled.png")
                    : require("../../../../assets/icons/heart.png")
                }
              />
            </div>
            <div onClick={() => handleIconClick("clap")}>
              <img
                src={
                  iconHovered == "clap"
                    ? require("../../../../assets/icons/clap-filled.png")
                    : require("../../../../assets/icons/clap.png")
                }
              />
            </div>

            <p>{count + "+"}</p>
          </div>
        </div>
      </div>

      <div className="share-post-c">
        <h3>Share This Post</h3>

        <div className="footer-socials-c share-post">
          <div
            className="footer-social-i"
            onClick={() => {
              window.open(
                `https://www.facebook.com/sharer/sharer.php?t=${item.attributes?.title}+by+Mishov+Markets&u=${window.location.href}`
              );
            }}
          >
            <img src={facebookIcon} style={{ width: "100%", height: "100%" }} />
          </div>

          <div
            className="footer-social-i"
            onClick={() => {
              window.open(
                `http://x.com/share?text=${item.attributes?.title}+by+Mishov+Markets&url=${window.location.href}`
              );
            }}
          >
            <XIcon style={{ color: "#fff", fontSize: 30 }} />
          </div>

          <div
            className="footer-social-i"
            onClick={() => {
              window.open(
                `https://telegram.me/share/url?text=${item.attributes?.title}+by+Mishov+Markets&url=${window.location.href}`
              );
            }}
          >
            <TelegramIcon style={{ color: "#fff", fontSize: 35 }} />
          </div>

          <div
            className="footer-social-i"
            onClick={() => {
              window.open(
                `https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`
              );
            }}
          >
            <img src={linkedInIcon} style={{ width: "100%", height: "100%" }} />
          </div>
        </div>
      </div>
    </>
  );
}
