import React, { useEffect, useRef, useState } from "react";
import "./articles.css";
import { API, SERVER_DOMAIN } from "../../../../assets/staticData";
import { Link } from "react-router-dom";
import flame from "../../../../assets/icons/flame-icon.svg";

export default function Articles() {
  const apiKey = process.env.REACT_APP_API_KEY;

  const [articles, setArticles] = useState([]);
  const scrollContainerRef = useRef(null);
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${API}/instrument`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          method: "GET",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setData(data.data?.attributes);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  const handleMouseDown = (e) => {
    const scrollContainer = scrollContainerRef.current;
    setIsDown(true);
    setStartX(e.pageX - scrollContainer.offsetLeft);
    setScrollLeft(scrollContainer.scrollLeft);
  };

  const handleMouseLeave = () => {
    setIsDown(false);
  };

  const handleMouseUp = () => {
    setIsDown(false);
  };

  const handleMouseMove = (e) => {
    if (!isDown) return;
    e.preventDefault();
    const scrollContainer = scrollContainerRef.current;
    const x = e.pageX - scrollContainer.offsetLeft;
    const walk = (x - startX) * 2;
    scrollContainer.scrollLeft = scrollLeft - walk;
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `${API}/articles?filters[lang][$eq]=English&filters[public][$eq]=true`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${apiKey}`,
            },
            method: "GET",
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setArticles(data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="ins-articles-c">
      <h2>articles</h2>

      <div className="ins-articles-c-c">
        <div className="ins-a-side-a">
          <h3>
            Posts
            <br />
            from
            <br />
            our
            <br />
            Blog
          </h3>
        </div>
        <div
          className="ins-a-side-b"
          ref={scrollContainerRef}
          onMouseDown={handleMouseDown}
          onMouseLeave={handleMouseLeave}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}>
          {articles.length > 0 &&
            articles.map((item, index) => (
              <div
                key={index}
                className="ins-a-card"
                style={{ marginRight: index == articles.length - 1 ? 15 : 0 }}>
                <h3>{item.attributes?.title}</h3>
                <p>{item.attributes?.subTitle?.slice(0, 90) + "..."}</p>

                <div>
                  <img src={SERVER_DOMAIN + item.attributes?.authorPFP} />
                  <h4>{item.attributes?.author}</h4>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className="ins-a-btns-c">
        <Link
          to={data.data && data.data[0]?.buttons[3]?.link}
          className="hero-btn"
          target="_"
          style={{
            marginLeft: 0,
            width: "auto",
            paddingLeft: 20,
            paddingRight: 20,
          }}>
          <img src={flame} alt="Flame Icon" />
          <p>{data.data && data.data[0]?.buttons[3]?.text}</p>
        </Link>
        <Link
          to={data.data && data.data[0]?.buttons[4]?.link}
          className="ins-outlined-btn"
          target="_"
          style={{
            marginLeft: 0,
            width: "auto",
            paddingLeft: 20,
            paddingRight: 20,
          }}>
          <p>{data.data && data.data[0]?.buttons[4]?.text}</p>
        </Link>
      </div>
    </div>
  );
}
