import React, { useEffect, useState } from 'react'
import Hero from './sections/hero/Hero'
import Body from './sections/body/Body'
import Question from "../about/sections/question/Question";
import { API, SERVER_DOMAIN } from '../../assets/staticData';
import { Helmet } from 'react-helmet-async';


export default function AccountTypes({seo}) {

    const apiKey = process.env.REACT_APP_API_KEY;

    const [data, setData] = useState([]);

    useEffect(() => {
      async function fetchData() {
        try {
          const response = await fetch(`${API}/account-type`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${apiKey}`,
            },
            method: "GET",
          });
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          const data = await response.json();
          setData(data.data?.attributes);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }

      fetchData();
    }, []);

  return (
    <div>
      <Helmet>
        <title>
          {seo[6]?.metaTitle ? seo[6]?.metaTitle : "Mishov Markets"}
        </title>
        <meta name="description" content={seo[6]?.metaDesc} />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={seo[6]?.socialTitle} />
        <meta property="og:description" content={seo[6]?.socialDesc} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={SERVER_DOMAIN + seo[6]?.socialImg} />
        <meta name="robots" content={seo[6]?.indexingRules} />
        <meta name="keywords" content={seo[6]?.keywords} />
        <script type="application/ld+json">{seo[6]?.schema}</script>
      </Helmet>
      <Hero data={data} />
      <Body data={data} />
      <Question />
    </div>
  );
}
