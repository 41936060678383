// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.post-hero-c {
  padding-top: 10rem;
  position: relative;
}
.post-hero-c > h1 {
  font-family: "Butler";
  font-size: 40px;
  text-transform: uppercase;
  color: var(--primary);
  font-weight: 900;
  z-index: 2;
}
.post-meta-c {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  margin: 20px 0;
  align-self: flex-start;
  margin-left: 6%;
  flex-wrap: wrap;
}
.post-meta-c > div {
  font-size: 15px;
}
.post-content-c {
  margin: 4rem auto;
  text-align: justify;
  padding: 0 30px;
  line-height: 25px;
}
.post-featured-image {
  width: 90%;
  aspect-ratio: 16/9;
  object-fit: cover;
}

@media (max-width: 700px) {
  .post-meta-c {
    gap: 15px;
    margin-left: 4%;
  }
  .post-hero-c > h1 {
    font-size: 30px;
  }
  .post-hero-c {
    padding-top: 8rem;
  }
  .post-content-c {
    margin: 20px auto;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/post/sections/hero/hero.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,qBAAqB;EACrB,eAAe;EACf,yBAAyB;EACzB,qBAAqB;EACrB,gBAAgB;EAChB,UAAU;AACZ;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,SAAS;EACT,cAAc;EACd,sBAAsB;EACtB,eAAe;EACf,eAAe;AACjB;AACA;EACE,eAAe;AACjB;AACA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,eAAe;EACf,iBAAiB;AACnB;AACA;EACE,UAAU;EACV,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE;IACE,SAAS;IACT,eAAe;EACjB;EACA;IACE,eAAe;EACjB;EACA;IACE,iBAAiB;EACnB;EACA;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".post-hero-c {\n  padding-top: 10rem;\n  position: relative;\n}\n.post-hero-c > h1 {\n  font-family: \"Butler\";\n  font-size: 40px;\n  text-transform: uppercase;\n  color: var(--primary);\n  font-weight: 900;\n  z-index: 2;\n}\n.post-meta-c {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  gap: 20px;\n  margin: 20px 0;\n  align-self: flex-start;\n  margin-left: 6%;\n  flex-wrap: wrap;\n}\n.post-meta-c > div {\n  font-size: 15px;\n}\n.post-content-c {\n  margin: 4rem auto;\n  text-align: justify;\n  padding: 0 30px;\n  line-height: 25px;\n}\n.post-featured-image {\n  width: 90%;\n  aspect-ratio: 16/9;\n  object-fit: cover;\n}\n\n@media (max-width: 700px) {\n  .post-meta-c {\n    gap: 15px;\n    margin-left: 4%;\n  }\n  .post-hero-c > h1 {\n    font-size: 30px;\n  }\n  .post-hero-c {\n    padding-top: 8rem;\n  }\n  .post-content-c {\n    margin: 20px auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
