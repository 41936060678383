import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { API } from "../../assets/staticData";

const RedirectHandler = () => {
  const [redirects, setRedirects] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const apiKey = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    const fetchRedirects = async () => {
      try {
        const response = await fetch(`${API}/redirects`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          method: "GET",
        });
        const data = await response.json();
        setRedirects(data.data);
      } catch (error) {
        console.error("Error fetching redirects:", error);
      }
    };

    fetchRedirects();
  }, []);

  useEffect(() => {
    const currentPath = window.location.href;

    const redirect = redirects.find(
      (redirect) => currentPath === redirect.attributes.source
    );

    if (redirect) {
      navigate(redirect.attributes.destination);
    }
  }, [redirects, location.pathname]);

  return null;
};

export default RedirectHandler;
