// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-hero-c {
  padding-top: 170px;
  padding-bottom: 50px;
  max-width: 1400px;
  position: relative;
  margin: 0 auto;
}
.p-hero-c > h1 {
  text-transform: uppercase;
  color: var(--primary);
  font-family: "Butler";
  font-size: 8rem;
}
.p-hero-mac {
  width: 60%;
}
.p-hero-tablet {
  position: absolute;
  top: 58%;
  right: 10%;
  width: 23%;
}
.p-hero-mobile {
  position: absolute;
  left: 14%;
  top: 37%;
  width: 10%;
}

@media (max-width: 900px) {
  .p-hero-c > h1 {
    font-size: 6rem;
  }
  .p-hero-mobile {
    top: 45%;
  }
}

@media (max-width: 600px) {
  .p-hero-c > h1 {
    font-size: 3rem;
  }
  .p-hero-mobile {
    top: 48%;
  }
  div.p-hero-c > div {
    margin-top: 50px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/platforms/sections/hero/hero.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,oBAAoB;EACpB,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;AAChB;AACA;EACE,yBAAyB;EACzB,qBAAqB;EACrB,qBAAqB;EACrB,eAAe;AACjB;AACA;EACE,UAAU;AACZ;AACA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,UAAU;AACZ;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,UAAU;AACZ;;AAEA;EACE;IACE,eAAe;EACjB;EACA;IACE,QAAQ;EACV;AACF;;AAEA;EACE;IACE,eAAe;EACjB;EACA;IACE,QAAQ;EACV;EACA;IACE,2BAA2B;EAC7B;AACF","sourcesContent":[".p-hero-c {\n  padding-top: 170px;\n  padding-bottom: 50px;\n  max-width: 1400px;\n  position: relative;\n  margin: 0 auto;\n}\n.p-hero-c > h1 {\n  text-transform: uppercase;\n  color: var(--primary);\n  font-family: \"Butler\";\n  font-size: 8rem;\n}\n.p-hero-mac {\n  width: 60%;\n}\n.p-hero-tablet {\n  position: absolute;\n  top: 58%;\n  right: 10%;\n  width: 23%;\n}\n.p-hero-mobile {\n  position: absolute;\n  left: 14%;\n  top: 37%;\n  width: 10%;\n}\n\n@media (max-width: 900px) {\n  .p-hero-c > h1 {\n    font-size: 6rem;\n  }\n  .p-hero-mobile {\n    top: 45%;\n  }\n}\n\n@media (max-width: 600px) {\n  .p-hero-c > h1 {\n    font-size: 3rem;\n  }\n  .p-hero-mobile {\n    top: 48%;\n  }\n  div.p-hero-c > div {\n    margin-top: 50px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
