import React from "react";
import "./hero.css";
import { SERVER_DOMAIN } from "../../../../assets/staticData";

export default function Hero({ data }) {
  return (
    <div className="ab-hero-c flex-col">
      <h1>About Us</h1>
      <img src={data.images ? SERVER_DOMAIN + data.images[0]?.headerImg : ""} />
    </div>
  );
}
 