import React from 'react'
import Body from './sections/body/Body'
import Hero from './sections/hero/Hero'

export default function Promotion() {
  return (
    <div>
        <Hero />
        <Body />
    </div>
  )
}
