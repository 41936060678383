// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pm-c {
  width: 100%;
  min-height: 250px;
  margin-top: 50px;
}
.pm-c > h3 {
  color: var(--primary);
  font-size: 1.3rem;
}
.pm-c-c {
  margin: 0 auto;
  max-width: 800px;
  margin-top: 60px;
}
.pm-single {
  width: 100px !important;
  height: 60px;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
}
.pm-single > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 700px) {
  .pm-c {
    min-height: 17vh;
  }
  .pm-single {
    width: 58px !important;
    height: 40px !important;
  }
  .pm-c-c {
    margin-top: 35px !important;
  }
  .pm-c > h3 {
    font-size: 1rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/home/sections/payment-methods/payment-methods.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,qBAAqB;EACrB,iBAAiB;AACnB;AACA;EACE,cAAc;EACd,gBAAgB;EAChB,gBAAgB;AAClB;AACA;EACE,uBAAuB;EACvB,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;AACA;EACE;IACE,gBAAgB;EAClB;EACA;IACE,sBAAsB;IACtB,uBAAuB;EACzB;EACA;IACE,2BAA2B;EAC7B;EACA;IACE,eAAe;EACjB;AACF","sourcesContent":[".pm-c {\n  width: 100%;\n  min-height: 250px;\n  margin-top: 50px;\n}\n.pm-c > h3 {\n  color: var(--primary);\n  font-size: 1.3rem;\n}\n.pm-c-c {\n  margin: 0 auto;\n  max-width: 800px;\n  margin-top: 60px;\n}\n.pm-single {\n  width: 100px !important;\n  height: 60px;\n  border-radius: 5px;\n  overflow: hidden;\n  cursor: pointer;\n}\n.pm-single > img {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n@media (max-width: 700px) {\n  .pm-c {\n    min-height: 17vh;\n  }\n  .pm-single {\n    width: 58px !important;\n    height: 40px !important;\n  }\n  .pm-c-c {\n    margin-top: 35px !important;\n  }\n  .pm-c > h3 {\n    font-size: 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
