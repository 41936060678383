// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-what-c {
  max-width: 1200px;
  margin: 0 auto;
  padding: 100px 0;
}
.p-what-c > h2 {
  font-size: 3.5rem;
  -webkit-text-stroke: 1px var(--primary);
  text-transform: uppercase;
  font-family: sans-serif;
  font-weight: 800;
  color: #fff;
}
.p-what-list-c {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  gap: 40px;
  margin-top: 40px;
}
.p-what-list-i {
  width: 40%;
  text-align: justify;
  min-width: 350px;
}
.p-what-list-i > h3 {
  font-size: 2.3rem;
  text-transform: uppercase;
}
.p-what-list-i > p {
  font-weight: 500;
}

.colorful-border {
  border: 2px solid transparent;
  padding: 20px;
  font-family: Arial, sans-serif;
  max-width: 400px;
  border-radius: 15px;
  /* background: linear-gradient(to right, white, white),
    linear-gradient(to right, transparent 50%, #9365DB, #0099FF); */
  background-clip: padding-box, border-box !important;
  background-origin: padding-box, border-box !important;
}

@media (max-width: 900px) {
  .p-what-c > h2 {
    font-size: 2.5rem;
    padding: 0 7%;
  }
}

@media (max-width: 600px) {
  .p-what-c > h2 {
    font-size: 2.3rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/platforms/sections/what/what.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,cAAc;EACd,gBAAgB;AAClB;AACA;EACE,iBAAiB;EACjB,uCAAuC;EACvC,yBAAyB;EACzB,uBAAuB;EACvB,gBAAgB;EAChB,WAAW;AACb;AACA;EACE,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,6BAA6B;EAC7B,SAAS;EACT,gBAAgB;AAClB;AACA;EACE,UAAU;EACV,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,iBAAiB;EACjB,yBAAyB;AAC3B;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;EAC7B,aAAa;EACb,8BAA8B;EAC9B,gBAAgB;EAChB,mBAAmB;EACnB;mEACiE;EACjE,mDAAmD;EACnD,qDAAqD;AACvD;;AAEA;EACE;IACE,iBAAiB;IACjB,aAAa;EACf;AACF;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".p-what-c {\n  max-width: 1200px;\n  margin: 0 auto;\n  padding: 100px 0;\n}\n.p-what-c > h2 {\n  font-size: 3.5rem;\n  -webkit-text-stroke: 1px var(--primary);\n  text-transform: uppercase;\n  font-family: sans-serif;\n  font-weight: 800;\n  color: #fff;\n}\n.p-what-list-c {\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  justify-content: space-around;\n  gap: 40px;\n  margin-top: 40px;\n}\n.p-what-list-i {\n  width: 40%;\n  text-align: justify;\n  min-width: 350px;\n}\n.p-what-list-i > h3 {\n  font-size: 2.3rem;\n  text-transform: uppercase;\n}\n.p-what-list-i > p {\n  font-weight: 500;\n}\n\n.colorful-border {\n  border: 2px solid transparent;\n  padding: 20px;\n  font-family: Arial, sans-serif;\n  max-width: 400px;\n  border-radius: 15px;\n  /* background: linear-gradient(to right, white, white),\n    linear-gradient(to right, transparent 50%, #9365DB, #0099FF); */\n  background-clip: padding-box, border-box !important;\n  background-origin: padding-box, border-box !important;\n}\n\n@media (max-width: 900px) {\n  .p-what-c > h2 {\n    font-size: 2.5rem;\n    padding: 0 7%;\n  }\n}\n\n@media (max-width: 600px) {\n  .p-what-c > h2 {\n    font-size: 2.3rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
