import React, { useEffect, useState } from "react";
import "./account-types.css";
import { API } from "../../../../assets/staticData";
import { Link } from "react-router-dom";

export default function AccountTypes() {
  const [plans, setPlans] = useState([]);
  const apiKey = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    async function fetchMenu() {
      try {
        const response = await fetch(`${API}/account-type`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          method: "GET",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch languages");
        }
        const data = await response.json();
        setPlans(data.data?.attributes?.accountTypesData[0].menu);
      } catch (error) {
        console.error("Error fetching languages:", error);
      }
    }

    fetchMenu();
  }, []);

  return (
    <div className="at-c">
      <h2>Account Types</h2>
      <div className="at-c-c">
        {plans.map((item, index) => (
          <div key={index} className="plan-c">
            <div className="plan-c-c">
              <p>{item.title}</p>
              <div className="divider"></div>
              <div className="plan-items-c">
                {item.dropDownItems?.map((item, index) => (
                  <div key={index} className="flex" style={{ gap: 10 }}>
                    <div>
                      <p className="plan-label-text">{"• " + item.title}</p>
                      <p className="plan-value-text">{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <Link target="_" className="plan-btn flex" to={item.btn1L}>
              Choose This Account
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
