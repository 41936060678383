import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "./nav.css";
import localLogo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { API, SERVER_DOMAIN } from "../../assets/staticData";
import {
  Bars3Icon,
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import { animated, useSpring } from "@react-spring/web";
import { Drawer } from "antd";
import { toast } from "react-toastify";
import { HashLink } from "react-router-hash-link";

function Nav() {
  const [selectedLang, setSelectedLang] = useState({});
  const [langs, setLangs] = useState([]);
  const [logo, setLogo] = useState();
  const [scrolled, setScolled] = useState(false);
  const [langDD, setLangDD] = useState(false);
  const [hovered, setHovered] = useState([]);
  const [dropDowns, setDropdowns] = useState([]);
  const [searchOpen, setSearchOpen] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [resMenuItem, setResMenuItem] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [searchV, setSearchV] = useState("");
  const searchRef = useRef(null);
  const inputRef = useRef(null);

  const { width, borderWidth } = useSpring({
    width: searchOpen ? 180 : 30,
    borderWidth: searchOpen ? 1 : 0,
    config: { duration: 1 },
  });

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 60) {
        setScolled(true);
      } else {
        setScolled(false);
      }
    });
  }, []);

  const apiKey = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    async function fetchLanguages() {
      try {
        const response = await fetch(
          `${API}/languages?filters[active][$eq]=true&populate=*`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${apiKey}`,
            },
            method: "GET",
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch languages");
        }
        const data = await response.json();
        setLangs(data.data);
        let hoverDocs = [];
        data.data?.map(() => {
          hoverDocs.push({ show: false });
        });
        setHovered(hoverDocs);
      } catch (error) {
        console.error("Error fetching languages:", error);
      }
    }

    fetchLanguages();
  }, []);

  useEffect(() => {
    async function fetchMenu() {
      try {
        const response = await fetch(`${API}/menu`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          method: "GET",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch languages");
        }
        const data = await response.json();
        setMenuItems(data.data?.attributes?.menuData[0].menu);
        let dropDownsDocs = [];
        data.data?.attributes?.menuData[0]?.menu?.map(() => {
          dropDownsDocs.push({ show: false });
        });
        setDropdowns(dropDownsDocs);
        let resMenuItemDocs = [];
        data.data?.attributes?.menuData[0]?.menu?.map(() => {
          resMenuItemDocs.push({ open: false });
        });
        setResMenuItem(resMenuItemDocs);
      } catch (error) {
        console.error("Error fetching languages:", error);
      }
    }

    async function fetchLogo() {
      try {
        const response = await fetch(`${API}/general-settings`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          method: "GET",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch languages");
        }
        const data = await response.json();

        setLogo(data?.data?.attributes?.generalData[0]?.logo);
      } catch (error) {
        console.error("Error fetching languages:", error);
      }
    }

    fetchLogo();
    fetchMenu();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setSearchOpen(false);
      }
    };

    // Bind the event listener
    document.addEventListener("click", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const scrollWithOffset = (el) => {
    const yOffset = -130;
    const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  return (
    <div
      className="navCC"
      style={{
        backgroundColor: scrolled ? "#fff" : null,
        boxShadow: scrolled ? "0 0 7px #00000040" : null,
      }}
    >
      <div className="navC">
        <div className="logoC">
          <Link to="/">
            <img src={logo ? SERVER_DOMAIN + logo : localLogo} />
          </Link>
        </div>
        <div className="menuC">
          <ul className="menu">
            {menuItems.map((item, index) => (
              <div
                key={index}
                style={{ position: "relative" }}
                onMouseEnter={() => {
                  setDropdowns([
                    ...dropDowns.map((item, idx) => {
                      if (idx === index) {
                        return { ...item, show: true };
                      } else {
                        return { ...item, show: false };
                      }
                    }),
                  ]);
                }}
                onMouseLeave={() => {
                  setDropdowns([
                    ...dropDowns.map((item, idx) => {
                      return { ...item, show: false };
                    }),
                  ]);
                }}
              >
                <Link
                  to={item.url ? item.url : item.title.replace(/[ /\\]/g, "-")}
                  onClick={() => {
                    setDropdowns([
                      ...dropDowns.map((item, idx) => {
                        return { ...item, show: false };
                      }),
                    ]);
                  }}
                  className="menu-item"
                >
                  <li>{item.title}</li>
                </Link>
                {item.dropDownItems && (
                  <div
                    style={{
                      height: 275,
                      top: 40,
                      left: "-190%",
                      opacity: dropDowns[index]?.show ? 1 : 0,
                      visibility: dropDowns[index]?.show ? "visible" : "hidden",
                      position: "absolute",
                      paddingTop: 15,
                    }}
                  >
                    <div
                      style={{
                        height: 260,
                        backgroundColor: "#fff",
                        transition: "0.3s",
                        overflow: "hidden",
                        boxShadow: "0 0 5px #00000030",
                        borderRadius: 7,
                        textAlign: "left",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                        padding: "0px 20px",
                      }}
                    >
                      <div
                        className="dropdown-section flex-col"
                        style={{ flex: 0.3 }}
                      >
                        <img src={SERVER_DOMAIN + item.icon} />
                        <p>{item.description}</p>
                      </div>
                      <div
                        className="dropdown-section"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flexWrap: "wrap",
                          height: 200,
                          minWidth:
                            item.dropDownItems?.length > 3 ? 480 : "auto",
                        }}
                      >
                        {item.dropDownItems?.map((itm, indx) => (
                          <div key={indx + 75}>
                            {item.title == "Market" ? (
                              <HashLink
                                scroll={scrollWithOffset}
                                smooth
                                to={
                                  itm.url
                                    ? itm.url
                                    : itm.title.replace(/[ /\\]/g, "-")
                                }
                                onClick={() => {
                                  setDropdowns([
                                    ...dropDowns.map((item, idx) => {
                                      return { ...item, show: false };
                                    }),
                                  ]);
                                }}
                                className="dropdown-item"
                                style={{
                                  display: "flex",
                                  alignItems: "flex-start",
                                  justifyContent: "flex-start",
                                  gap: 10,
                                  minWidth: 180,
                                  height: 60,
                                  cursor: "pointer",
                                  color: "var(--primary)",
                                }}
                              >
                                <div>
                                  <img
                                    style={{ width: 23, aspectRatio: 1 }}
                                    src={SERVER_DOMAIN + itm.icon}
                                  />
                                </div>
                                <div style={{ width: 200 }}>
                                  <h4>{itm.title}</h4>
                                  <p
                                    style={{
                                      fontSize: 12,
                                      color: "#888",
                                      paddingRight: 15,
                                    }}
                                  >
                                    {itm.description}
                                  </p>
                                </div>
                              </HashLink>
                            ) : (
                              <Link
                                target={item.title == "Platforms" ? "_" : ""}
                                onClick={() => {
                                  setDropdowns([
                                    ...dropDowns.map((item, idx) => {
                                      return { ...item, show: false };
                                    }),
                                  ]);

                                  if (item.title == "Platforms") {
                                    toast("Your download has been started", {
                                      position: "bottom-left",
                                      autoClose: 5000,
                                      hideProgressBar: false,
                                      closeOnClick: true,
                                      pauseOnHover: true,
                                      draggable: true,
                                      progress: undefined,
                                      theme: "light",
                                      type: "success",
                                    });
                                  }
                                }}
                                to={
                                  itm.url
                                    ? itm.url
                                    : itm.title.replace(/[ /\\]/g, "-")
                                }
                                className="dropdown-item"
                                style={{
                                  display: "flex",
                                  alignItems: "flex-start",
                                  justifyContent: "flex-start",
                                  gap: 10,
                                  minWidth: 180,
                                  height: 60,
                                  cursor: "pointer",
                                  color: "var(--primary)",
                                }}
                              >
                                <div>
                                  <img
                                    style={{ width: 23, aspectRatio: 1 }}
                                    src={SERVER_DOMAIN + itm.icon}
                                  />
                                </div>
                                <div style={{ width: 200 }}>
                                  <h4>{itm.title}</h4>
                                  <p
                                    style={{
                                      fontSize: 12,
                                      color: "#888",
                                      paddingRight: 15,
                                    }}
                                  >
                                    {itm.description}
                                  </p>
                                </div>
                              </Link>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </ul>
        </div>
        <div className="lrC flex">
          <animated.div
            ref={searchRef}
            className="nav-search-icon flex"
            style={{
              cursor: "pointer",
              width: width,
              border: "solid",
              borderWidth: 0,
              borderColor: "var(--primary)",
              borderWidth,
              borderRadius: 25,
              padding: "4px 7px",
            }}
            onClick={() => {
              setSearchOpen(true);
              inputRef.current.focus();
            }}
          >
            <MagnifyingGlassIcon width={27} color="var(--primary)" />
            <input
              ref={inputRef}
              value={searchV}
              onChange={(e) => setSearchV(e.target.value)}
              className="search-input"
              style={{
                //  visibility: searchOpen ? "visible" : "hidden",
                width: searchOpen ? "90%" : 0,
                height: searchOpen ? null : 0,
              }}
            />
          </animated.div>

          <Link
            to={"https://my.mishovmarkets.com/"}
            className="outline-btn flex"
          >
            <p>JOIN</p>
          </Link>
          <div
            onMouseEnter={() => setLangDD(true)}
            onMouseLeave={() => setLangDD(false)}
            style={{ cursor: "pointer", gap: 3, position: "relative" }}
            className="flex"
          >
            <img
              className="lang-img-header"
              style={{ width: 33, marginRight: 5 }}
              src={
                selectedLang?.attributes?.icon?.data?.attributes?.url
                  ? SERVER_DOMAIN +
                    selectedLang?.attributes?.icon?.data?.attributes?.url
                  : `${SERVER_DOMAIN}/uploads/icons8_great_britain_96_eb3cd4ac96.png`
              }
            />
            <div style={{ minWidth: 25 }}>
              <h3 style={{ color: "var(--primary)", fontWeight: "600" }}>
                {"En"}
              </h3>
            </div>
            <div style={{ width: 22, paddingTop: 5 }}>
              <ChevronDownIcon className="nav-icon" />
            </div>
            <div
              style={{
                width: 170,
                top: 45,
                left: -30,
                opacity: langDD ? 1 : 0,
                visibility: langDD ? "visible" : "hidden",
                backgroundColor: "#fff",
                position: "absolute",
                transition: "0.3s",
                overflow: "hidden",
                boxShadow: "0 0 5px #00000030",
                borderRadius: 7,
                textAlign: "left",
              }}
              onMouseLeave={() => {
                setHovered([
                  ...hovered.map((item, idx) => {
                    return { ...item, show: false };
                  }),
                ]);
              }}
            >
              {langs?.map((item, index) => {
                return (
                  <Link
                    to={
                      item.attributes.language == "Arabic"
                        ? "https://mishovmarkets.com/ar/"
                        : item.attributes.language == "Persian"
                        ? "https://mishovmarkets.com/fa/"
                        : null
                    }
                    onClick={() => {
                      setLangDD(false);
                    }}
                    onMouseEnter={() =>
                      setHovered([
                        ...hovered.map((item, idx) => {
                          if (idx === index) {
                            return { ...item, show: true };
                          } else {
                            return { ...item, show: false };
                          }
                        }),
                      ])
                    }
                    key={index}
                    style={{
                      height: 48,
                      justifyContent: "flex-start",
                      gap: 7,
                      position: "relative",
                      padding: "0 10px",
                    }}
                    className="flex"
                  >
                    {hovered[index]?.show && (
                      <div
                        style={{
                          position: "absolute",
                          top: "5%",
                          left: "2.5%",
                          width: "95%",
                          height: "90%",
                          backgroundColor: "#25aceb50",
                          transition: "0.2s",
                          borderRadius: 5,
                        }}
                      ></div>
                    )}
                    <img
                      style={{ position: "relative" }}
                      width={28}
                      src={
                        SERVER_DOMAIN + item.attributes.icon.data.attributes.url
                      }
                    />
                    <p
                      style={{
                        color: "var(--primary)",
                        fontSize: 15,
                        position: "relative",
                        fontWeight: "600",
                      }}
                    >
                      {item.attributes.language}
                    </p>
                  </Link>
                );
              })}
            </div>
          </div>
          <div
            className="res-menu-trigger flex"
            onClick={() => setMobileMenu(true)}
          >
            <Bars3Icon width={33} color="var(--primary)" />
          </div>
        </div>
      </div>
      <div>
        <Drawer
          title=""
          placement="right"
          onClose={() => setMobileMenu(false)}
          open={mobileMenu}
          key={"drawer"}
          className="res-drawer"
        >
          <div
            className="flex"
            style={{
              border: "solid 1px var(--primary)",
              borderRadius: 50,
              height: 40,
            }}
          >
            <input
              placeholder="Search..."
              className="search-input"
              style={{ height: "100%", width: "84%" }}
            />
            <MagnifyingGlassIcon width={27} color="var(--primary)" />
          </div>
          {menuItems.map((item, index) => (
            <div key={index} style={{ position: "relative", paddingTop: 10 }}>
              <Link
                onClick={() => setMobileMenu(false)}
                to={item.url ? item.url : item.title.replace(/[ /\\]/g, "-")}
                style={{
                  color: "var(--primary)",
                  fontSize: 18,
                }}
              >
                {item.title}
              </Link>

              {item.dropDownItems?.length > 0 && (
                <ChevronDownIcon
                  onClick={() => {
                    if (resMenuItem[index]?.open) {
                      setResMenuItem([
                        ...resMenuItem.map((item, idx) => {
                          if (idx === index) {
                            return { ...item, open: false };
                          }
                        }),
                      ]);
                    } else {
                      setResMenuItem([
                        ...resMenuItem.map((item, idx) => {
                          if (idx === index) {
                            return { ...item, open: true };
                          } else {
                            return { ...item, open: false };
                          }
                        }),
                      ]);
                    }
                  }}
                  width={25}
                  color="var(--primary)"
                  style={{
                    position: "absolute",
                    right: 0,
                    padding: 7,
                    marginTop: -10,
                  }}
                />
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 7,
                  gap: 5,
                  overflow: "hidden",
                  transition: "0.4s",
                  minHeight: resMenuItem[index]?.open ? 100 : 0,
                  maxHeight: resMenuItem[index]?.open ? 200 : 0,
                  paddingTop: resMenuItem[index]?.open ? 5 : 0,
                }}
              >
                {item.dropDownItems?.map((itm, index) => (
                  <Link
                    onClick={() => setMobileMenu(false)}
                    to={itm.url ? itm.url : itm.title.replace(/[ /\\]/g, "-")}
                    key={index + 888}
                    className="flex"
                    style={{
                      justifyContent: "flex-start",
                      gap: 10,
                      color: "var(--primary)",
                      fontWeight: "500",
                    }}
                  >
                    <img
                      style={{ width: 20, aspectRatio: 1 }}
                      src={SERVER_DOMAIN + itm.icon}
                    />

                    {itm.title}
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </Drawer>
      </div>
    </div>
  );
}

export default Nav;
