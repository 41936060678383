import React, { useEffect, useState, useRef } from "react";
import bg from "../../../../assets/images/hero-bg.svg";
import "./hero.css";
import flame from "../../../../assets/icons/flame-icon.svg";
import arrowDown from "../../../../assets/icons/arrow-down-icon.svg";
import heroGlobe from "../../../../assets/video/hero-globe.webm";
import { API } from "../../../../assets/staticData";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { toast } from "react-toastify";

const apiKey = process.env.REACT_APP_API_KEY;

export default function HeroSection() {
  const [data, setData] = useState([]);
  const videoRef = useRef(null);
  const [videoVisible, setVideoVisible] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${API}/hero-banner`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          method: "GET",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setData(data.data?.attributes?.bannerData[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setVideoVisible(true); // Set videoVisible to true when the placeholder is in view
          observer.disconnect(); // Disconnect the observer after making the video visible
        }
      },
      {
        rootMargin: "200px", // Load the video a bit before it comes into view
        threshold: 0.1,
      }
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  // const wrapFirstWord = (text) => {
  //   if (!text) return "";

  //   const words = text.split(" ");
  //   const firstWord = words.shift();
  //   const restOfText = words.join(" ");

  //   return `<span>${firstWord}</span> ${restOfText}`;
  // };

  return (
    <div className="c">
      <img className="hero-bg" src={bg} alt="Hero Background" />

      <div className="content-c">
        <div className="side-a">
          <div>
            {/* <h1
              className="hero-h1"
              dangerouslySetInnerHTML={{ __html: wrapFirstWord(data.slogan) }}
            /> */}

            <h1 className="hero-h1">{data.slogan}</h1>
          </div>

          {data.desc && <p>{parse(`${data.desc}`)}</p>}
          <Link target="_" to={data.btnL} className="hero-btn">
            <img src={flame} alt="Flame Icon" />
            <p>{data.btnT}</p>
          </Link>
        </div>
        <div className="side-b">
          {!videoVisible ? (
            <div ref={videoRef} className="video-placeholder"></div>
          ) : (
            <video className="hero-video" autoPlay loop muted playsInline>
              <source src={heroGlobe} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      </div>
      <div className="scroll-down-circle-c">
        <div
          className="scroll-down-circle flex"
          onClick={() => {
            window.scrollTo(0, 730);
          }}>
          <img src={arrowDown} alt="Arrow Down Icon" />
        </div>
      </div>
    </div>
  );
}
