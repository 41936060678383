import React, { useState } from "react";
import "./login.css";
import logo from "../../assets/images/logo.png";
import { TextField } from "@mui/material";

export default function Login() {
  const correctPass = "56rhfwfhjedbg544ghghsf3453";

  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  const submit = () => {
    if (password == correctPass) {
      sessionStorage.setItem("loginToken", true);
      window.location.reload();
    } else {
      setError(true);
    }
  };

  return (
    <div className="login-c flex">
      <img
        className="login-bg"
        src={require("../../assets/images/login-bg.png")}
      />
      <h2>Coming</h2>
      <h2>Soon</h2>

      <div className="input-c flex-col">
        <img src={logo} style={{ width: "22%" }} />
        <p>Access is available via password only</p>
        <TextField
          placeholder="Password"
          size="small"
          sx={{ backgroundColor: "#fff", borderRadius: 25, width: 300 }}
          InputProps={{
            type: "password",
            style: {
              borderRadius: "25px",
            },
          }}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            setError(false);
          }}
        />
        <div
          className="login-err flex"
          style={{
            height: error ? 30 : 0,
            visibility: error ? "visible" : "hidden",
            marginBottom: error ? 0 : -20,
          }}
        >
          Incorrect Password
        </div>
        <div onClick={submit} style={{ marginTop: 0 }} className="hero-btn">
          <p>Confirm</p>
        </div>
      </div>
    </div>
  );
}
