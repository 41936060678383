import React from "react";
import "./404.css";
import img404 from "../../assets/images/404.png";
import logo from "../../assets/images/footer-logo-mini.svg";
import { Link } from "react-router-dom";

export default function FourOFour() {
  return (
    <div className="c-404">
      <div className="title-404 flex-col">
        <h1>404</h1>
        <h2>Page Not Found</h2>
      </div>
      <img className="logo-404" src={logo} />
      <img className="img-404" src={img404} />
      <div className="action-404">
        <p>Something went wrong!</p>
        <Link
          to="/"
          style={{ marginTop: 17, width: 250 }}
          className="outlined-btn flex"
        >
          Go To Home Page
        </Link>
      </div>
    </div>
  );
}
