import React, { useEffect, useState } from "react";
import "./footer.css";
import { API, SERVER_DOMAIN } from "../../../../assets/staticData";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import TelegramIcon from "@mui/icons-material/Telegram";
import footerBG from "../../../../assets/images/footer-bg.svg";
import footerLogo from "../../../../assets/images/footer-logo.svg";
import footerLogoMini from "../../../../assets/images/footer-logo-mini.svg";
import facebookIcon from "../../../../assets/icons/facebook-icon.svg";
import linkedInIcon from "../../../../assets/icons/linkedin-icon.svg";
import locationIcon from "../../../../assets/icons/location-icon.svg";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

export default function Footer() {
  const [socials, setSocials] = useState({});
  const [data, setData] = useState(null);

  const apiKey = process.env.REACT_APP_API_KEY;

 

  useEffect(() => {
    async function fetchSocials() {
      try {
        const response = await fetch(`${API}/social-media`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          method: "GET",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        const englishObject = data.data?.attributes?.socialMediaData?.find(
          (item) => item.language === "English"
        );
        setSocials(englishObject);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    async function fetchData() {
      try {
        const response = await fetch(`${API}/footer`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          method: "GET",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const res = await response.json();

        setData(res.data?.attributes);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchSocials();
    fetchData();
  }, []);
  return (
    <div className="footer-c">
      <img src={footerBG} className="footer-bg" />
      <img src={footerLogo} className="footer-logo" />
      <div className="footer-socials-c">
        {socials.youtube && (
          <div
            className="footer-social-i"
            onClick={() => window.open(socials.youtube)}
          >
            <YouTubeIcon style={{ color: "#fff", fontSize: 40 }} />
          </div>
        )}
        {socials.linkedIn && (
          <div
            className="footer-social-i"
            onClick={() => window.open(socials.linkedIn)}
          >
            <img src={linkedInIcon} style={{ width: "100%", height: "100%" }} />
          </div>
        )}
        {socials.telegram && (
          <div
            className="footer-social-i"
            onClick={() => window.open(socials.telegram)}
          >
            <TelegramIcon style={{ color: "#fff", fontSize: 40 }} />
          </div>
        )}
        {socials.x && (
          <div
            className="footer-social-i"
            onClick={() => window.open(socials.x)}
          >
            <XIcon style={{ color: "#fff", fontSize: 35 }} />
          </div>
        )}
        {socials.instagram && (
          <div
            className="footer-social-i"
            onClick={() => window.open(socials.instagram)}
          >
            <InstagramIcon style={{ color: "#fff", fontSize: 40 }} />
          </div>
        )}
        {socials.facebook && (
          <div
            className="footer-social-i"
            onClick={() => window.open(socials.facebook)}
          >
            <img src={facebookIcon} style={{ width: "100%", height: "100%" }} />
          </div>
        )}
      </div>

      <div className="footer-c-c">
        <div className="footer-links-c">
          {data?.menuData &&
            data?.menuData[0]?.menu?.map((item, index) => (
              <div className="footer-links-i" key={index}>
                <h3>{item.title}</h3>
                {item.dropDownItems?.map((item, index) => (
                  <div key={index + 90} className="footer-links-m-i">
                    <Link
                      className="link-no-ul footer-links-m-i-a"
                      to={item.url}
                    >
                      {item.title}
                    </Link>
                  </div>
                ))}
              </div>
            ))}
        </div>
        <div>
          {data?.textArea && (
            <div style={{ textAlign: "justify", padding: "0 15px" }}>
              {parse(`${data.textArea[0]?.textArea}`)}
            </div>
          )}
        </div>
      </div>
      <div className="footer-bottom">
        {data && <img src={SERVER_DOMAIN + data?.footerImg[0]?.img} />}
        <div className="footer-copyright">
          <img
            style={{ marginBottom: 20, opacity: 0.7, width: 130 }}
            src={footerLogoMini}
          />

          <p>{`Copyright © 2015 - ${new Date().getFullYear()} All rights reserved by Mishov Markets`}</p>

          {/* <div className="footer-bottom-links">
            {data?.textArea &&
              [...Array(4)].map((_, index) => (
                <div key={index} className="footer-links-m-i">
                  <Link
                    className="link-no-ul footer-links-m-i-a"
                    to={data.textArea[0]?.[`hll${index + 1}`]}
                  >
                    {data.textArea[0]?.[`hlt${index + 1}`]}
                  </Link>
                </div>
              ))}
          </div> */}
        </div>
        <div style={{ marginRight: 18 }}>
          <div className="flex" style={{ gap: 10, marginBottom: 5 }}>
            <img src={locationIcon} width={30} />
            <p
              style={{
                color: "#dfdfdf",
                fontWeight: "300",
                fontSize: 13,
                textAlign: "left",
                maxWidth: 240,
              }}
            >
              {data?.textArea && data.textArea[0]?.address}
            </p>
          </div>
          <div style={{}}>
            {data?.textArea && (
              <iframe
                frameBorder={0}
                width="280"
                height="270"
                id="gmap_canvas"
                src={`https://maps.google.com/maps?q=${data.textArea[0]?.mapLat},${data.textArea[0]?.mapLong}&hl=en&z=11&output=embed`}
              ></iframe>
            )}

            <script
              type="text/javascript"
              src="https://embedmaps.com/google-maps-authorization/script.js?id=f5d38a35665740d1c09c82b782b9da516c527395"
            ></script>
          </div>
        </div>
      </div>
    </div>
  );
}
