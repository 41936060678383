import React from "react";
import "./body.css";

export default function Body() {
  const cards = [
    "Qui excepteur nisi velit velit. Incididunt enim culpa pariatur commodo Lorem proident id sint deserunt. Non esse aliqua ullamco exercitation",
    "Qui excepteur nisi velit velit. Incididunt enim culpa pariatur commodo Lorem proident id sint deserunt. Non esse aliqua ullamco exercitation",
    "Qui excepteur nisi velit velit. Incididunt enim culpa pariatur commodo Lorem proident id sint deserunt. Non esse aliqua ullamco exercitation",
    "Qui excepteur nisi velit velit. Incididunt enim culpa pariatur commodo Lorem proident id sint deserunt. Non esse aliqua ullamco exercitation",
  ];

  const table = [
    {
      header: [
        "Bounus Type",
        "Required Deposit",
        "Maximum Required Trades Per Month",
        "Maximum Bonus",
      ],
      body: [
        { a: "$500", b: "5 Lots", c: "$175" },
        { a: "$500", b: "5 Lots", c: "$175" },
        { a: "$500", b: "5 Lots", c: "$175" },
        { a: "$500", b: "5 Lots", c: "$175" },
        { a: "$500", b: "5 Lots", c: "$175" },
        { a: "$500", b: "5 Lots", c: "$175" },
      ],
    },
  ];

  return (
    <div className="promo-b-c flex-col">
      <img
        className="promo-top-lines"
        src={require("../../../../assets/images/promo-top-lines.png")}
      />
      <h2>earn bonus cash</h2>
      <h3>while you trade</h3>

      <p>
        Qui excepteur nisi velit velit. Incididunt enim culpa pariatur commodo
        Lorem proident id sint deserunt. Non esse aliqua ullamco exercitation
        qui culpa quis velit ad nisi id. Quis nostrud laborum cillum duis
        laboris ut excepteur ex non incididunt. Qui excepteur nisi velit velit.
        Incididunt enim culpa pariatur commodo Lorem proident id sint deserunt.
        Non esse aliqua ullamco exercitation qui culpa quis velit ad nisi id.
        Quis nostrud laborum cillum duis laboris ut excepteur ex non incididunt.
        excepteur nisi velit velit. Incididunt enim culpa pariatur commodo Lorem
        proident id sint deserunt. Non esse aliqua ullamco exercitation qui
        culpa quis velit ad nisi id. Quis nostrud laborum cillum duis laboris ut
        excepteur ex non incididunt.
      </p>

      <div
        className="hero-btn middle"
        style={{
          marginLeft: 0,
          boxShadow: "0 2px 5px var(--link)",
          position: "relative",
        }}
      >
        <img src={require("../../../../assets/icons/diamond-icon.png")} />
        <p>Join Promotion</p>
      </div>

      <div className="promo-cards-c">
        {cards.map((item, index) => (
          <div key={index} className="promo-card flex">
            <p>{item}</p>
          </div>
        ))}
      </div>

      <div className="promo-analysis-c">
        <img src={require("../../../../assets/images/promo-side-lines.png")} />

        <img src={require("../../../../assets/images/promo-side-lines2.png")} />

        <img
          className="promo-analysis"
          src={require("../../../../assets/images/promo-analysis.webp")}
        />
      </div>

      <h3 style={{ fontWeight: "700", fontSize: 33 }}>Title Something</h3>
      <p>
        Qui excepteur nisi velit velit. Incididunt enim culpa pariatur commodo
        Lorem proident id sint deserunt. Non esse aliqua ullamco exercitation
        qui culpa quis velit ad nisi id. Quis nostrud laborum cillum duis
        laboris ut excepteur ex non incididunt. Qui excepteur nisi velit velit.
        Incididunt enim culpa pariatur commodo Lorem proident id sint deserunt.
        Non esse aliqua ullamco exercitation qui culpa quis velit ad nisi id.
        Quis nostrud laborum cillum duis laboris ut excepteur ex non incididunt.
        excepteur nisi velit velit. Incididunt enim culpa pariatur commodo Lorem
        proident id sint deserunt. Non esse aliqua ullamco exercitation qui
        culpa quis velit ad nisi id. Quis nostrud laborum cillum duis laboris ut
        excepteur ex non incididunt.
      </p>

      <div className="white-btn-c">
        <img src={require("../../../../assets/images/promo-dots.png")} />
        <div className="white-btn flex">
          <img src={require("../../../../assets/icons/star-icon.png")} />
          <p>Promote Yourself</p>
        </div>
      </div>

      <img
        className="promo-credit"
        src={require("../../../../assets/images/promo-credit.PNG")}
      />

      <h3 style={{ fontWeight: "700", fontSize: 33 }}>Title Something</h3>
      <p>
        Qui excepteur nisi velit velit. Incididunt enim culpa pariatur commodo
        Lorem proident id sint deserunt. Non esse aliqua ullamco exercitation
        qui culpa quis velit ad nisi id. Quis nostrud laborum cillum duis
        laboris ut excepteur ex non incididunt. Qui excepteur nisi velit velit.
        Incididunt enim culpa pariatur commodo Lorem proident id sint deserunt.
        Non esse aliqua ullamco exercitation qui culpa quis velit ad nisi id.
        Quis nostrud laborum cillum duis laboris ut excepteur ex non incididunt.
        excepteur nisi velit velit. Incididunt enim culpa pariatur commodo Lorem
        proident id sint deserunt. Non esse aliqua ullamco exercitation qui
        culpa quis velit ad nisi id. Quis nostrud laborum cillum duis laboris ut
        excepteur ex non incididunt.
      </p>

      <div className="promo-table">
        <div className="promo-table-header">
          {table[0].header.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
        </div>
        <div className="promo-table-body">
          {table[0].body.map((item, index) => (
            <div key={index} className="promo-table-row">
              <p className="flex">{index + 1}</p>
              <p></p>
              <p>{item.a}</p>
              <p>{item.b}</p>
              <p>{item.c}</p>
            </div>
          ))}
        </div>
      </div>

      <h3 style={{ fontWeight: "700", fontSize: 33 }}>Title Something</h3>
      <p>
        Qui excepteur nisi velit velit. Incididunt enim culpa pariatur commodo
        Lorem proident id sint deserunt. Non esse aliqua ullamco exercitation
        qui culpa quis velit ad nisi id. Quis nostrud laborum cillum duis
        laboris ut excepteur ex non incididunt. Qui excepteur nisi velit velit.
        Incididunt enim culpa pariatur commodo Lorem proident id sint deserunt.
        Non esse aliqua ullamco exercitation qui culpa quis velit ad nisi id.
        Quis nostrud laborum cillum duis laboris ut excepteur ex non incididunt.
        excepteur nisi velit velit. Incididunt enim culpa pariatur commodo Lorem
        proident id sint deserunt. Non esse aliqua ullamco exercitation qui
        culpa quis velit ad nisi id. Quis nostrud laborum cillum duis laboris ut
        excepteur ex non incididunt.
      </p>

      <div className="promo-terms">
        <div>
          <p>Promotion Terms & Conditions</p>
          <img
            src={require("../../../../assets/icons/download-icon.png")}
            style={{ width: 23, marginLeft: 5 }}
          />
        </div>
        <div>
          <div>Create Demo Account</div>
          <div>Create Live Account</div>
        </div>
      </div>
    </div>
  );
}
