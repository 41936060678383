import React, { useEffect, useState } from "react";
import "./why.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HelpIcon from "@mui/icons-material/Help";
import { SERVER_DOMAIN } from "../../../../assets/staticData";

export default function Why({ data }) {
  const [infoCards, setInfoCards] = useState([]);

  const whyWeUse = [
    {
      title: "Order Execution Types",
      info: "",
    },
    {
      title: "Partial Order Filling Policies",
      info: "",
    },
    {
      title: "Pending Order Types",
      info: "",
    },
    {
      title: "Order Fill Policy",
      info: "",
    },
    {
      title: "Netting",
      info: "",
    },
    {
      title: "Reports",
      info: "",
    },
    {
      title: "Balance Transaction Types",
      info: "",
    },
    {
      title: "Graphical Objects",
      info: "",
    },
    {
      title: "Economic Calendar",
      info: "",
    },
    {
      title: "Strategy Tester",
      info: "",
    },
    {
      title: "Email System",
      info: "",
    },
    {
      title: "Fund Transfer Between Accounts",
      info: "",
    },
    {
      title: "Embedded MQL5 Community Chat",
      info: "",
    },
    {
      title: "Crypto",
      info: "",
    },
    {
      title: "Bond Markets",
      info: "",
    },
    {
      title: "Option Markets",
      info: "",
    },
    {
      title: "ETF",
      info: "",
    },
  ];

  useEffect(() => {
    let showDocs = [];
    whyWeUse.map(() => {
      showDocs.push({ show: false });
    });
    setInfoCards(showDocs);
  }, []);

  return (
    <>
      <div className="p-why-c">
        <h2>
          why<span> we use </span>mt5<span>?</span>
        </h2>

        <div className="p-why-list-c">
          {whyWeUse.map((item, index) => (
            <div key={index} className="p-why-list-i">
              <div className="flex" style={{ gap: 5 }}>
                <p>{item.title}</p>
                <div style={{ position: "relative" }}>
                  <HelpIcon
                    className="p-why-info-icon"
                    sx={{ fontSize: 20, color: "#999" }}
                    onMouseEnter={() =>
                      setInfoCards([
                        ...infoCards.map((item, idx) => {
                          if (idx === index) {
                            return { ...item, show: true };
                          } else {
                            return { ...item, show: false };
                          }
                        }),
                      ])
                    }
                    onMouseLeave={() =>
                      setInfoCards([
                        ...infoCards.map((item, idx) => {
                          if (idx === index) {
                            return { ...item, show: false };
                          } else {
                            return item;
                          }
                        }),
                      ])
                    }
                  />

                  <div
                    style={{
                      width: 160,
                      height: 80,
                      top: -105,
                      left: -80,
                      opacity: infoCards[index]?.show ? 1 : 0,
                      visibility: infoCards[index]?.show ? "visible" : "hidden",
                      backgroundColor: "#fff",
                      position: "absolute",
                      transition: "0.5s",
                      overflow: "hidden",
                      boxShadow: "0 0 10px #00000030",
                      borderRadius: 7,
                      padding: 10,
                      textAlign: "left",
                      zIndex: 999,
                    }}
                  >
                    <p
                      style={{ color: "#666", fontSize: 12, fontWeight: "400" }}
                    >
                      Nisi aute velit sit incididunt cillum nostrud eu occaecat.
                      Et laborum et veniam sint magna enim in ullamco.
                    </p>
                  </div>
                </div>
              </div>
              <CheckCircleIcon sx={{ color: "var(--link)" }} />
            </div>
          ))}
        </div>
      </div>
      <div style={{ position: "relative" }}>
        <img src={data.images && SERVER_DOMAIN + data.images[0].middleBG} />
        <div className="p-why-text-on-pic">
          <p>{data.platformData && data.platformData[0].middleText}</p>
        </div>
      </div>
    </>
  );
}
