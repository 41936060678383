// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dw-b-c {
  max-width: 900px;
  margin: 15rem auto;
  padding: 0 20px;
}

.dw-b-c > h2 {
  color: var(--primary);
}
.dw-b-c > p {
  text-align: justify;
  line-height: 26px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/d&w/sections/body/body.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,qBAAqB;AACvB;AACA;EACE,mBAAmB;EACnB,iBAAiB;AACnB","sourcesContent":[".dw-b-c {\n  max-width: 900px;\n  margin: 15rem auto;\n  padding: 0 20px;\n}\n\n.dw-b-c > h2 {\n  color: var(--primary);\n}\n.dw-b-c > p {\n  text-align: justify;\n  line-height: 26px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
