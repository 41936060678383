// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.plc-why-c {
  min-height: 60vh;
  max-width: 750px;
  margin: 0 auto;
  padding: 90px 10px 50px 10px;
}

.plc-why-c > h2 {
  color: var(--primary);
  margin-bottom: 0px;
  font-weight: 700;
}
.plc-why-c > p {
  margin-bottom: 10px;
  line-height: 25px;
  font-weight: 500;
  text-align: justify;
}

@media (max-width: 750px) {
  .plc-why-c {
    padding: 100px 20px 60px 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/plc/sections/why/why.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;EACd,4BAA4B;AAC9B;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE;IACE,6BAA6B;EAC/B;AACF","sourcesContent":[".plc-why-c {\n  min-height: 60vh;\n  max-width: 750px;\n  margin: 0 auto;\n  padding: 90px 10px 50px 10px;\n}\n\n.plc-why-c > h2 {\n  color: var(--primary);\n  margin-bottom: 0px;\n  font-weight: 700;\n}\n.plc-why-c > p {\n  margin-bottom: 10px;\n  line-height: 25px;\n  font-weight: 500;\n  text-align: justify;\n}\n\n@media (max-width: 750px) {\n  .plc-why-c {\n    padding: 100px 20px 60px 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
