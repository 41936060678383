// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cat-c {
  padding: 10rem 0 5rem 0;
  min-height: 70vh;
  max-width: 1000px;
  margin: 0 auto;
}
.cat-c-c > h3 {
  color: var(--primary);
  font-size: 4rem;
  font-family: "Butler";
  text-align: left;
  font-weight: 800;
}
.cat-c-c > p {
  text-align: justify;
  line-height: 24px;
  margin-top: 1rem;
}
.cat-c-c > h4 {
  color: var(--primary);
  font-size: 1.6rem;
  font-family: "Butler";
  text-align: left;
  font-weight: 800;
  margin-top: 40px;
}

@media (max-width: 1020px) {
  .cat-c {
    padding: 10rem 15px 5rem 15px;
  }
  .in-cat-page {
    justify-content: center !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/category/category.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;AAChB;AACA;EACE,qBAAqB;EACrB,eAAe;EACf,qBAAqB;EACrB,gBAAgB;EAChB,gBAAgB;AAClB;AACA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,qBAAqB;EACrB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE;IACE,6BAA6B;EAC/B;EACA;IACE,kCAAkC;EACpC;AACF","sourcesContent":[".cat-c {\n  padding: 10rem 0 5rem 0;\n  min-height: 70vh;\n  max-width: 1000px;\n  margin: 0 auto;\n}\n.cat-c-c > h3 {\n  color: var(--primary);\n  font-size: 4rem;\n  font-family: \"Butler\";\n  text-align: left;\n  font-weight: 800;\n}\n.cat-c-c > p {\n  text-align: justify;\n  line-height: 24px;\n  margin-top: 1rem;\n}\n.cat-c-c > h4 {\n  color: var(--primary);\n  font-size: 1.6rem;\n  font-family: \"Butler\";\n  text-align: left;\n  font-weight: 800;\n  margin-top: 40px;\n}\n\n@media (max-width: 1020px) {\n  .cat-c {\n    padding: 10rem 15px 5rem 15px;\n  }\n  .in-cat-page {\n    justify-content: center !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
