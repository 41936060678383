import React from "react";
import "./how.css";
import { Link } from "react-router-dom";
import tickIcon from "../../../../assets/icons/tick-icon.svg";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

export default function How({ data }) {
  const cards = ["", "", ""];

  return (
    <div className="ab-how-c">
      <h2>{data.aboutData && data.aboutData[0].cardsHeading}</h2>

      <div>
        {data.aboutData &&
          data.aboutData[0]?.cards?.map((item, index) => (
            <div
              key={index}
              className="ab-how-sec"
              style={{ flexDirection: index == 1 ? "row-reverse" : "row" }}
            >
              <div className="ab-how-card">
                <p>{item.text}</p>
                <img className="ab-tick-icon" src={tickIcon} />
              </div>
              <div className="flex" style={{ gap: 5, width: 400 }}>
                <Link
                  to={item.link}
                  className="ab-how-link flex"
                  style={{ gap: 7 }}
                >
                  {item.linkText}{" "}
                  <KeyboardDoubleArrowRightIcon
                    sx={{
                      color: "var(--primary)",
                      fontSize: 33,
                    }}
                  />
                </Link>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
