// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.countdown-mini {
  display: flex;
  align-items: center;
  font-family: "Arial", sans-serif;
  justify-content: center;
  border-radius: 10px;
  gap: 7px;

}

.countdown-unit-mini {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.countdown-digits-mini {
  display: flex;
  gap: 5px;
}

.countdown-digit-mini {
  font-size: 20px;
  color: #00174d;
  background-color: white;
  border-radius: 5px;
  padding: 7px 0;
  width: 28px;
  text-align: center;
  font-weight: 600;
}

.countdown-label-mini {
  font-size: 9px;
  color: #ffffff;
  margin-bottom: 5px;
}

.countdown-separator-mini {
  font-size: 23px;
  font-weight: bold;
  color: #ffffff;
  margin: 14px 0px 0 0px;
}
/* 
@media (max-width: 750px) {
  .countdown-digit {
    width: 20px;
    font-size: 25px;
  }
  .countdown-separator {
    font-size: 25px;
    margin: 13px 3px 0 3px;
  }
}

@media (max-width: 500px) {
  .countdown-digit {
    width: 14px;
    font-size: 20px;
    padding: 12px 8px;
  }
  .countdown-separator {
    font-size: 22px;
    margin: 13px 0px 0 0px;
  }
} */
`, "",{"version":3,"sources":["webpack://./src/components/countdown-mini/countdownmini.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,gCAAgC;EAChC,uBAAuB;EACvB,mBAAmB;EACnB,QAAQ;;AAEV;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,QAAQ;AACV;;AAEA;EACE,eAAe;EACf,cAAc;EACd,uBAAuB;EACvB,kBAAkB;EAClB,cAAc;EACd,WAAW;EACX,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,sBAAsB;AACxB;AACA;;;;;;;;;;;;;;;;;;;;;;GAsBG","sourcesContent":[".countdown-mini {\n  display: flex;\n  align-items: center;\n  font-family: \"Arial\", sans-serif;\n  justify-content: center;\n  border-radius: 10px;\n  gap: 7px;\n\n}\n\n.countdown-unit-mini {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.countdown-digits-mini {\n  display: flex;\n  gap: 5px;\n}\n\n.countdown-digit-mini {\n  font-size: 20px;\n  color: #00174d;\n  background-color: white;\n  border-radius: 5px;\n  padding: 7px 0;\n  width: 28px;\n  text-align: center;\n  font-weight: 600;\n}\n\n.countdown-label-mini {\n  font-size: 9px;\n  color: #ffffff;\n  margin-bottom: 5px;\n}\n\n.countdown-separator-mini {\n  font-size: 23px;\n  font-weight: bold;\n  color: #ffffff;\n  margin: 14px 0px 0 0px;\n}\n/* \n@media (max-width: 750px) {\n  .countdown-digit {\n    width: 20px;\n    font-size: 25px;\n  }\n  .countdown-separator {\n    font-size: 25px;\n    margin: 13px 3px 0 3px;\n  }\n}\n\n@media (max-width: 500px) {\n  .countdown-digit {\n    width: 14px;\n    font-size: 20px;\n    padding: 12px 8px;\n  }\n  .countdown-separator {\n    font-size: 22px;\n    margin: 13px 0px 0 0px;\n  }\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
