import React from "react";
import parse from "html-react-parser";


export default function Why({data}) {
  return (
    <div className="plc-why-c">
      {data.data && parse(`${data.data[0]?.body}`)}
    </div>
  );
}
 