// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog-follow-c {
  max-width: 1100px;
  margin: 10rem auto;
  position: relative;
  height: 400px;
}

.blog-follow-c > img {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.b-f-side-a,
.b-f-side-b {
  position: relative;
}
.b-f-side-a {
  height: 100%;
  width: 45%;
  align-items: center;
  justify-content: center;
  display: flex;
}
.b-f-side-a > img {
  width: 55%;
}

.b-f-side-b {
  width: 55%;
  display: flex;
  flex-direction: column;
}

.b-f-side-b > h2 {
  color: #fff;
  font-size: 2.1rem;
}
.b-f-side-b > p {
  color: #fff;
  font-size: 20px;
  margin-top: 5px;
}

@media (max-width: 1000px) {
  .blog-follow-c > img {
    width: 95%;
    left: 2.5%;
    height: 90%;
  }
}
@media (max-width: 800px) {
  .b-f-side-b {
    width: 65%;
  }
}
@media (max-width: 650px) {
  .blog-follow-c {
    flex-direction: column;
  }
  .blog-follow-c > img {
    height: 110%;
    border-radius: 50px;
  }
  .b-f-side-b {
    width: 100%;
  }
  .b-f-side-a {
    width: 75%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/blog/sections/follow/follow.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,OAAO;EACP,MAAM;AACR;;AAEA;;EAEE,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,UAAU;EACV,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;AACf;AACA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,iBAAiB;AACnB;AACA;EACE,WAAW;EACX,eAAe;EACf,eAAe;AACjB;;AAEA;EACE;IACE,UAAU;IACV,UAAU;IACV,WAAW;EACb;AACF;AACA;EACE;IACE,UAAU;EACZ;AACF;AACA;EACE;IACE,sBAAsB;EACxB;EACA;IACE,YAAY;IACZ,mBAAmB;EACrB;EACA;IACE,WAAW;EACb;EACA;IACE,UAAU;EACZ;AACF","sourcesContent":[".blog-follow-c {\n  max-width: 1100px;\n  margin: 10rem auto;\n  position: relative;\n  height: 400px;\n}\n\n.blog-follow-c > img {\n  width: 100%;\n  position: absolute;\n  left: 0;\n  top: 0;\n}\n\n.b-f-side-a,\n.b-f-side-b {\n  position: relative;\n}\n.b-f-side-a {\n  height: 100%;\n  width: 45%;\n  align-items: center;\n  justify-content: center;\n  display: flex;\n}\n.b-f-side-a > img {\n  width: 55%;\n}\n\n.b-f-side-b {\n  width: 55%;\n  display: flex;\n  flex-direction: column;\n}\n\n.b-f-side-b > h2 {\n  color: #fff;\n  font-size: 2.1rem;\n}\n.b-f-side-b > p {\n  color: #fff;\n  font-size: 20px;\n  margin-top: 5px;\n}\n\n@media (max-width: 1000px) {\n  .blog-follow-c > img {\n    width: 95%;\n    left: 2.5%;\n    height: 90%;\n  }\n}\n@media (max-width: 800px) {\n  .b-f-side-b {\n    width: 65%;\n  }\n}\n@media (max-width: 650px) {\n  .blog-follow-c {\n    flex-direction: column;\n  }\n  .blog-follow-c > img {\n    height: 110%;\n    border-radius: 50px;\n  }\n  .b-f-side-b {\n    width: 100%;\n  }\n  .b-f-side-a {\n    width: 75%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
