import React from "react";
import Hero from "./sections/hero/Hero";
import About from "./sections/about/About";
import GlobalMarket from "./sections/global-market/GlobalMarket";
import ChooseUs from "./sections/why-choose-us/ChooseUs";
import Counters from "./sections/counters/Counters";
import Map from "./sections/map/Map";
import AccountTypes from "./sections/acoount-types/AccountTypes";
import Platforms from "./sections/platforms/Platforms";
import Blog from "./sections/blog/Blog";
import Faq from "./sections/faq/Faq";
import { Helmet } from "react-helmet-async";
import { SERVER_DOMAIN } from "../../assets/staticData";


function Home({seo}) {



  return (
    <div>
      <Helmet>
        <title>{seo[0]?.metaTitle}</title>
        <meta name="description" content={seo[0]?.metaDesc} />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={seo[0]?.socialTitle} />
        <meta property="og:description" content={seo[0]?.socialDesc} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={SERVER_DOMAIN + seo[0]?.socialImg} />
        <meta name="robots" content={seo[0]?.indexingRules} />
        <meta name="keywords" content={seo[0]?.keywords} />
        <script type="application/ld+json">
          {seo[0]?.schema}
        </script>
      </Helmet>
      <Hero />
      <About />
      <GlobalMarket />
      <ChooseUs />
      <Counters />
      <Map />
      <AccountTypes />
      <Platforms />
      <Blog />
      <Faq />
    </div>
  );
}

export default Home;
