import React, { useEffect, useState } from "react";
import "./body.css";
import { API, SERVER_DOMAIN } from "../../../../assets/staticData";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Promos from "../promos/Promos";

export default function Body({ data }) {
  const [faqs, setFaqs] = useState([]);
  const [openFaq, setOpenFaq] = useState();
  const apiKey = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${API}/contact-us`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          method: "GET",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch languages");
        }
        const data = await response.json();
        setFaqs(data.data?.attributes?.contactData[0]?.faqs?.slice(0, 4));
      } catch (error) {
        console.error("Error fetching languages:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="promos-body-c">
      <img
        className="promos-body-img"
        src={data.images && SERVER_DOMAIN + data.images[0].imgs[1]}
      />

      <div className="promos-body-section-a">
        <h2>{data.data && data.data[0].header1}</h2>
        <p>{data.data && data.data[0].desc1}</p>
      </div>

      <div className="promos-body-section-b">
        <div className="promos-cards-c">
          {data.data &&
            data.data[0].cards.map((item, index) => (
              <div
                key={index}
                className="promos-card"
                style={{
                  boxShadow: `0 0 10px ${
                    index == 0
                      ? "#0099FF80"
                      : index == 1
                      ? "#0066CC80"
                      : index == 2
                      ? "#001C8680"
                      : "#00010780"
                  }`,
                }}
              >
                <h3
                  style={{
                    color:
                      index == 0
                        ? "#0099FF"
                        : index == 1
                        ? "#0066CC"
                        : index == 2
                        ? "#001C86"
                        : "#000107",
                  }}
                >
                  {item.title}
                </h3>
                <p>{item.text}</p>
              </div>
            ))}
          <img
            className="promos-arrows-ver"
            src={require("../../../../assets/images/promos-arrows-ver.png")}
          />
        </div>
        <img
          className="promos-arrows"
          src={require("../../../../assets/images/promos-arrows.png")}
        />
      </div>

      <Promos />

      <div className="promos-body-section-c">
        <h2>{data.data && data.data[0].header2}</h2>

        <div className="flex">
          <div className="promos-faq-img-c">
            <img
              className="promos-faq-img"
              src={data.images && SERVER_DOMAIN + data.images[0].imgs[2]}
            />
          </div>
          <div className="promos-faq-c">
            {faqs.length > 0 &&
              faqs.map((item, index) => (
                <div
                  key={index}
                  className="promos-faq"
                  onClick={() =>
                    openFaq !== index ? setOpenFaq(index) : setOpenFaq(null)
                  }
                  style={{
                    maxHeight: openFaq == index ? 200 : 50,
                  }}
                >
                  <div className="promos-q-c">
                    <h4>{item.q}</h4>
                    <ExpandMoreIcon
                      style={{
                        fontSize: 35,
                        color: "var(--primary)",
                        transition: "0.3s",
                        transform: openFaq == index ? "rotate(180deg)" : null,
                      }}
                    />
                  </div>
                  <div
                    className="promos-a-c"
                    style={{
                      opacity: openFaq == index ? 1 : 0,
                    }}
                  >
                    <p>{item.a}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="promos-body-section-d">
        <h2>{data.data && data.data[0].header3}</h2>

        <img src={data.images && SERVER_DOMAIN + data.images[0].imgs[3]} />
      </div>
    </div>
  );
}
