import React from "react";
import "./hero.css";
import mac from "../../../../assets/images/apple-p-p.png";
import tablet from "../../../../assets/images/tablet-p-p.png";
import mobile from "../../../../assets/images/mobile-p-p.png";
import { Link } from "react-router-dom";

export default function Hero({ data }) {
  return (
    <div className="p-hero-c">
      <h1>platforms</h1>

      <div>
        <img src={mac} className="p-hero-mac" />
        <img src={tablet} className="p-hero-tablet" />
        <img src={mobile} className="p-hero-mobile" />
      </div>

      <div className="flex" style={{ gap: 15, marginTop: 30 }}>
        <Link
          target="_"
          to={data.platformData && data.platformData[0].btnOneLink}
          className="hero-btn"
          style={{ margin: 0 }}>
          <p>{data.platformData && data.platformData[0].btnOneText}</p>
        </Link>
        <Link
          target="_"
          to={data.platformData && data.platformData[0].btnTwoLink}
          className="outline-btn flex"
          style={{ width: 180, height: 40 }}>
          <p>{data.platformData && data.platformData[0].btnTwoText}</p>
        </Link>
      </div>
    </div>
  );
}
