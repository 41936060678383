// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.c-404 {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  position: relative;
  overflow: hidden;
}
.img-404 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.logo-404 {
  display: none;
}
.title-404 {
  position: absolute;
  top: 17%;
  left: 10%;
  z-index: 2;
}
.title-404 > h1 {
  font-size: 11rem;
  font-weight: 800;
  -webkit-text-stroke: 2px var(--primary);
  color: #fff;
  font-family: sans-serif;
}
.title-404 > h2 {
  color: var(--primary);
  font-size: 2.4rem;
  margin-top: -20px;
  font-weight: 600;
}
.action-404 {
  position: absolute;
  right: 10%;
  bottom: 10%;
  z-index: 2;
}
.action-404 > p {
  color: var(--primary);
  font-size: 1.6rem;
  font-weight: 600;
}

@media (max-width: 550px) {
  .img-404 {
    object-fit: contain;
    top: 10%;
  }
  .title-404 {
    left: 9%;
  }
  .action-404 {
    right: 13%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/404/404.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,UAAU;AACZ;AACA;EACE,aAAa;AACf;AACA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,UAAU;AACZ;AACA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,uCAAuC;EACvC,WAAW;EACX,uBAAuB;AACzB;AACA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,UAAU;AACZ;AACA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE;IACE,mBAAmB;IACnB,QAAQ;EACV;EACA;IACE,QAAQ;EACV;EACA;IACE,UAAU;EACZ;AACF","sourcesContent":[".c-404 {\n  width: 100vw;\n  height: 100vh;\n  background-color: #fff;\n  position: relative;\n  overflow: hidden;\n}\n.img-404 {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 1;\n}\n.logo-404 {\n  display: none;\n}\n.title-404 {\n  position: absolute;\n  top: 17%;\n  left: 10%;\n  z-index: 2;\n}\n.title-404 > h1 {\n  font-size: 11rem;\n  font-weight: 800;\n  -webkit-text-stroke: 2px var(--primary);\n  color: #fff;\n  font-family: sans-serif;\n}\n.title-404 > h2 {\n  color: var(--primary);\n  font-size: 2.4rem;\n  margin-top: -20px;\n  font-weight: 600;\n}\n.action-404 {\n  position: absolute;\n  right: 10%;\n  bottom: 10%;\n  z-index: 2;\n}\n.action-404 > p {\n  color: var(--primary);\n  font-size: 1.6rem;\n  font-weight: 600;\n}\n\n@media (max-width: 550px) {\n  .img-404 {\n    object-fit: contain;\n    top: 10%;\n  }\n  .title-404 {\n    left: 9%;\n  }\n  .action-404 {\n    right: 13%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
