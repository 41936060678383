import React from "react";
import Hero from "./sections/hero/Hero";
import Articles from "./sections/articles/Articles";
import Follow from "./sections/follow/Follow";
import { Helmet } from "react-helmet-async";
import { SERVER_DOMAIN } from "../../assets/staticData";

export default function Instruments({seo}) {
  return (
    <div>
      <Helmet>
        <title>
          {seo[7]?.metaTitle ? seo[7]?.metaTitle : "Mishov Markets"}
        </title>
        <meta name="description" content={seo[7]?.metaDesc} />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={seo[7]?.socialTitle} />
        <meta property="og:description" content={seo[7]?.socialDesc} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={SERVER_DOMAIN + seo[7]?.socialImg} />
        <meta name="robots" content={seo[7]?.indexingRules} />
        <meta name="keywords" content={seo[7]?.keywords} />
        <script type="application/ld+json">{seo[7]?.schema}</script>
      </Helmet>
      <Hero />
      <Follow />
      <Articles />
    </div>
  );
}
