import React from "react";
import "./why.css";
import Slider from "react-slick";
import flame from "../../../../assets/icons/flame-icon.svg";
import { Link } from "react-router-dom";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export default function Why({ data }) {
  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        onClick={onClick}
        style={{
          ...style,
          position: "absolute",
          right: -45,
          top: "40%",
          cursor: "pointer",
        }}
      >
        <ArrowForwardIosIcon
          sx={{
            transition: "0.2s",
            fontSize: 35,
            color: "var(--primary)",
            "&:hover": {
              color: "var(--hover)",
            },
          }}
        />
      </div>
    );
  };
  const PervArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        onClick={onClick}
        style={{
          ...style,
          position: "absolute",
          left: -45,
          top: "40%",
          cursor: "pointer",
        }}
      >
        <ArrowBackIosIcon
          sx={{
            transition: "0.2s",
            fontSize: 35,
            color: "var(--primary)",
            "&:hover": {
              color: "var(--hover)",
            },
          }}
        />
      </div>
    );
  };

  const settings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 900,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PervArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
    ],
  };

  const whyChoose = [
    {
      title: "24/7 Customer Support",
      desc: "Send message, call, email, maybe a pigeon? or as you wish...",
      url: "",
    },
    {
      title: "PCI DSS Certified",
      desc: "Send message, call, email, or as maybe stuff you wish...",
      url: "",
    },
    {
      title: "Multiple Regulatory",
      desc: "Send message, call, email, it could be real, or as you wish...",
      url: "",
    },
  ];

  return (
    <div className="ab-why-c">
      <h2>
        why<span> choose </span>mishov markets<span>?</span>
      </h2>

      <div>
        <Slider {...settings} className="ab-why-slider">
          {data.aboutData &&
            data.aboutData[0]?.slides?.map((item, index) => (
              <div key={index} className="ab-why-card">
                <div className="ab-why-card-mc">
                  <h3>{item.title}</h3>
                  <p>{item.desc}</p>
                  <Link
                    to={item.link}
                    className="ab-why-card-link flex"
                    style={{ color: "var(--hover)", gap: 5 }}>
                    <p>Learn more</p>
                    <ExpandCircleDownOutlinedIcon
                      sx={{ fontSize: 18, transform: "rotate(-90deg)" }}
                    />
                  </Link>
                </div>
              </div>
            ))}
        </Slider>
      </div>
      <Link
        to={data.aboutData && data.aboutData[0]?.slideBTNLink}
        className="hero-btn"
        target="_"
        style={{ margin: "0 auto", marginTop: 20 }}>
        <img src={flame} />
        <p>{data.aboutData && data.aboutData[0]?.slideBTNText}</p>
      </Link>
    </div>
  );
}
