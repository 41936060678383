import React, { useEffect, useState } from "react";
import Hero from "./sections/hero/Hero";
import { API } from "../../assets/staticData";
import Body from "./sections/body/Body";

export default function Promotions() {
  const apiKey = process.env.REACT_APP_API_KEY;
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${API}/promo-page`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          method: "GET",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setData(data.data?.attributes);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <div>
      <Hero data={data} />
      <Body data={data} />
    </div>
  );
}
