import React, { useEffect, useState } from "react";
import "./what.css";

export default function What({ data }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="p-what-c">
      <h2>what you can trade on mt5?</h2>

      <div className="p-what-list-c">
        {data.platformData &&
          data.platformData[0]?.slides?.map((item, index) => (
            <div
              key={index}
              className="p-what-list-i colorful-border"
              style={{
                background:
                  windowWidth < 600
                    ? index % 2 === 0
                      ? "linear-gradient(to right, white, white), linear-gradient(to right, transparent 50%, #9365DB, #0099FF)"
                      : "linear-gradient(to left, white, white), linear-gradient(to left, transparent 50%, #9365DB, #0099FF)"
                    : Math.floor(index / 2) % 2 === 0
                    ? "linear-gradient(to right, white, white), linear-gradient(to right, transparent 50%, #9365DB, #0099FF)"
                    : "linear-gradient(to left, white, white), linear-gradient(to left, transparent 50%, #9365DB, #0099FF)",
              }}
            >
              <h3>{item.title}</h3>
              <p>{item.desc}</p>
            </div>
          ))}
      </div>
    </div>
  );
}
