import React, { useEffect, useState } from "react";
import Hero from "./sections/hero/Hero";
import Question from "../about/sections/question/Question";
import Videos from "../help/sections/videos/Videos";
import Follow from "./sections/follow/Follow";
import Articles from "../help/sections/articles/Articles";
import LatestVids from "./sections/latest-vids/LatestVids";
import Market from "./market/Market";
import { API, SERVER_DOMAIN } from "../../assets/staticData";
import { Helmet } from "react-helmet-async";

export default function Blog({ seo }) {
  const apiKey = process.env.REACT_APP_API_KEY;

  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${API}/blog`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          method: "GET",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setData(data.data?.attributes);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          {seo[2]?.metaTitle ? seo[2]?.metaTitle : "Mishov Markets"}
        </title>
        <meta name="description" content={seo[2]?.metaDesc} />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={seo[2]?.socialTitle} />
        <meta property="og:description" content={seo[2]?.socialDesc} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={SERVER_DOMAIN + seo[2]?.socialImg} />
        <meta name="robots" content={seo[2]?.indexingRules} />
        <meta name="keywords" content={seo[2]?.keywords} />
        <script type="application/ld+json">{seo[2]?.schema}</script>
      </Helmet>
      <Hero data={data} />
      <Market />
      <LatestVids />
      <Articles howTo />
      <Follow data={data} />
      <Videos />
      <Question />
    </div>
  );
}
