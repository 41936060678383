import React, { useEffect, useState } from "react";
import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import parse from "html-react-parser";
import { SERVER_DOMAIN } from "../../../../assets/staticData";

const symbols = [
  { label: "AUDUSD" },
  { label: "EURUSD" },
  { label: "GBPUSD" },
  { label: "NZDUSD" },
  { label: "USDCAD" },
  { label: "USDCHF" },
  { label: "USDJPY" },
  { label: "AUDCAD" },
  { label: "AUDCHF" },
  { label: "AUDJPY" },
  { label: "CADJPY" },
  { label: "EURAUD" },
  { label: "EURCAD" },
  { label: "EURCHF" },
  { label: "EURGBP" },
  { label: "EURJPY" },
  { label: "EURNZD" },
  { label: "GBPAUD" },
  { label: "GBPCAD" },
  { label: "GBPCHF" },
  { label: "GBPJPY" },
  { label: "GBPNZD" },
  { label: "NZDCAD" },
  { label: "NZDCHF" },
  { label: "NZDJPY" },
  { label: "DAX40" },
  { label: "DJI30" },
  { label: "NASUSD" },
  { label: "SP500" },
  { label: "BRENT" },
  { label: "CRUDE" },
  { label: "XAGUSD" },
  { label: "XAUUSD" },
  { label: "AUDNZD" },
  { label: "CADCHF" },
  { label: "CHFJPY" },
];

export default function Hero({ data }) {
  const [spreadVal, setspreadVal] = useState("");
  const [digitVal, setdigitVal] = useState(0.00001);
  const [selectedContractOption, setSelectedContractOption] = useState(100000);
  const [selectedSymbolOption, setSelectedSymbolOption] = useState(symbols[0]);
  const [result, setResult] = useState(0.0);
  const [error, setError] = useState(false);

  const handleCalculate = () => {
    if (
      spreadVal == null ||
      spreadVal == "" ||
      digitVal == null ||
      digitVal == ""
    ) {
      setError(true);
    } else {
      let res = spreadVal * selectedContractOption * digitVal;
      setResult(res);
    }
  };

  const resetCalc = () => {
    setResult("0.00");
    setspreadVal("");
    setdigitVal("");
    setSelectedContractOption(100000);
  };

  return (
    <div className="plc-hero-c">
      <h2>SPREAD CALCULATOR</h2>

      <div className="plc-hero-c-c-o">
        <img src={data.images && SERVER_DOMAIN + data.images[0].headerImg} />

        <div className="flex plc-hero-c-c">
          <div className="plc-side-a flex-col">
            <div className="calc-c">
              <div>
                <div className="calc-row">
                  <p>Instrument</p>
                  <Autocomplete
                    disablePortal
                    disableClearable
                    forcePopupIcon={false}
                    selectOnFocus
                    size="small"
                    id="combo-box-demo"
                    options={symbols}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) =>
                      option.label === value.label
                    }
                    sx={{ width: 125 }}
                    renderInput={(params) => (
                      <TextField {...params} label="" sx={{ fontSize: 15 }} />
                    )}
                    value={selectedSymbolOption}
                    onChange={(event, newValue) => {
                      setSelectedSymbolOption(newValue);

                      if (newValue.label == "USDJPY") {
                        setdigitVal(0.001);
                        setSelectedContractOption(100000);
                      } else if (
                        newValue.label == "XAUUSD" ||
                        newValue.label == "XAGUSD"
                      ) {
                        setdigitVal(0.01);
                        setSelectedContractOption(100);
                      } else if (
                        newValue.label == "SP500" ||
                        newValue.label == "NASUSD" ||
                        newValue.label == "DJI30" ||
                        newValue.label == "DAX40"
                      ) {
                        setdigitVal(0.01);
                        setSelectedContractOption(10);
                      } else if (
                        newValue.label == "CRUDE" ||
                        newValue.label == "BRENT"
                      ) {
                        setdigitVal(0.01);
                        setSelectedContractOption(1000);
                      } else {
                        setdigitVal(0.00001);
                        setSelectedContractOption(100000);
                      }
                    }}
                  />
                </div>

                <div className="calc-row">
                  <p>Spread</p>
                  <input
                    className="calc-number-input"
                    type="number"
                    min={0}
                    placeholder="0.00"
                    value={spreadVal}
                    onChange={(e) => {
                      setspreadVal(e.target.value);
                      setError(false);
                    }}
                  />
                </div>
                <div className="calc-row">
                  <p>Digit</p>
                  <input
                    disabled
                    className="calc-number-input"
                    type="number"
                    min={0}
                    placeholder="0.00"
                    value={digitVal}
                    onChange={(e) => {
                      setdigitVal(e.target.value);
                      setError(false);
                    }}
                  />
                </div>
                <div className="calc-row">
                  <p>Contract Size</p>
                  <Select
                    sx={{
                      width: 125,
                      fontSize: 15,
                    }}
                    size="small"
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={selectedContractOption}
                    onChange={(e) => setSelectedContractOption(e.target.value)}
                  >
                    <MenuItem value={100000}>FX</MenuItem>
                    <MenuItem value={1000}>Oil</MenuItem>
                    <MenuItem value={10}>Index</MenuItem>
                    <MenuItem value={100}>Gold</MenuItem>
                    <MenuItem value={5000}>Silver</MenuItem>
                  </Select>
                </div>
                <div
                  className="calc-error flex"
                  style={{
                    height: error ? 30 : 0,
                  }}
                >
                  Please fill all the fields before calculating
                </div>
              </div>
              <div>
                <div className="calc-btn-c">
                  <div className="calc-btn-o flex" onClick={resetCalc}>
                    Reset
                  </div>
                  <div className="calc-btn flex" onClick={handleCalculate}>
                    Calculate
                  </div>
                </div>
                <div className="calc-result-c">
                  <p>Spread:</p>
                  <div>
                    <h3
                      className="flex-col"
                      style={{
                        marginRight: 5,
                      }}
                    >
                      $
                    </h3>
                    <h3
                      style={{
                        fontWeight: "600",
                        fontSize: 20,
                      }}
                    >
                      {result.toFixed(2)}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="plc-side-b">
            {data.data && parse(`${data.data[0].header}`)}
          </div>
        </div>
      </div>
    </div>
  );
}
